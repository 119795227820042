import { add_vueApp2 } from '../../packs/vue_helper'

import StudentsStats from "./students-stats.vue";
import PagePayments from "./page-payments.vue";
import PageTopsellers from "./page-topsellers.vue";
import PageSubscriptions from "./page-subscriptions.vue";

import PageLessons from "./page-lessons.vue";
document.addEventListener('DOMContentLoaded', () => {
    add_vueApp2('app-students-statistics', StudentsStats)
    add_vueApp2('app-stats-payments', PagePayments)
    add_vueApp2('app-stats-topsellers', PageTopsellers)
    add_vueApp2('app-stats-subscriptions', PageSubscriptions)
    add_vueApp2('app-stats-lessons', PageLessons)

})
