<template>
<div>


  <div class="row">

      <div class="col-md-10">
  <div class="form-group">
    <label>Nazev filtru</label>
    <input type="text" v-model="filter.name" class="form-control"/>
  </div>
      </div>
    <div class="colmd-2">
      <label>
        <input type="checkbox" v-model="filter.active"/>
        Aktivní
      </label>
    </div>


  </div>

  <vue-tabs>
    <v-tab title="Podmínky">
      <div class="row">
        <div class="col-md-4">

          <div class="form-group pt-3">
            <label>Kdy se spustí kontrola podmínek</label>
            <select class="form-control" v-model="filter.trigger_kind">
              <option value="timer_h">Periodicky 1/h</option>
              <option value="timer_d">Periodicky 1/den</option>
              <option disabled >Při dokončení něčeho</option>
            </select>


          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group pt-3">
          <div v-if="filter.trigger_kind=='timer_d'">
            <label> v kterou hodinu</label>
            <select class="form-control" v-model="filter.run_hour" >
              <option v-for="i in 24" :vlaue="i-i">{{i-1}}</option>
            </select>
          </div>
        </div>
        </div>


      </div>




      <div class="segment" v-for="seg in segments" :key="`seg${seg._id}`">
        <div class="segment-op text-center">
          <div class="btn-group" role="group" aria-label="Button group with nested dropdown" v-if="seg.segment_operator">
            <button type="button" :class="`btn ${seg.segment_operator=='AND' ? 'btn-success' : 'btn-secondary'}`" @click="seg.segment_operator='AND'">AND</button>
            <button type="button" :class="`btn ${seg.segment_operator=='OR' ? 'btn-success' : 'btn-secondary'}`" @click="seg.segment_operator='OR'">OR</button>
          </div>
        </div>
        <div class="segment-body">
          <button class="remove-segment" v-if="segments.length>1" @click="removeSegment(seg)"></button>

          <div class="cond-wrapper" v-for="cond in seg.conditions" v-if="cond._destroy!=true" :key="`cond${cond._id}`" >
            <WorkflowCondition  :condition="cond" :eshops="eshops"></WorkflowCondition>
            <div class="remove-cond" v-if="seg.conditions.length>1">
              <a class="text-danger"  href="#"  @click.prevent="removeCond(cond)" title="smazat podminku"><svg class="svg-inline--fa fa-times fa-w-11" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" data-fa-i2svg=""><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
              </a>
            </div>
          </div>


          <div>
            <button class="btn btn-smallish btn-outline-success" @click.prevent="addCondition(seg)">+ pridej podminku</button>
          </div>
        </div>
      </div>

      <div class="text-center">
        <button @click.prevent="addSegment" class="btn btn-warning ">Přidat další segment</button>
      </div>





    </v-tab>

    <v-tab title="Akce">
      <div class="col-md-12">
        <h5>Akce</h5>
      </div>
      <notify-action v-for="a in nf_actions" :action="a" :key="a._id" v-on:removeAction="removeAction"></notify-action>
      <div class="text-center">
        <button @click.prevent="addAction" class="btn btn-warning mt-2">Přidat další akci</button>
      </div>

    </v-tab>
  </vue-tabs>






  <div class="text-right mt-3">
    <button @click="saveChanges" class="btn  btn-primary">Ulož změny</button>
    <button @click="$emit('cancel')" class="btn  btn-secondary">Návrat bez uložení</button>

    <button @click.prevent="runFilter" class="btn btn-outline-dark">zkouška filtru</button>
  </div>



  <div>

  </div>
<label><input type="checkbox" v-model="show_debug"/> debug </label>

<pre v-if="show_debug">
  {{nf_actions}}
  ------------------
  {{segments}}
</pre>
</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import WorkflowCondition from "./condition.vue";
import axios from "axios";
import { VueEditor } from "vue2-editor";
import NotifyAction from "./notify-action.vue";

export default {

  name: "editor",
  props:['eshops', 'filter'],
  components: {NotifyAction, WorkflowCondition, VueEditor},
  data(){
    return {
      nf_active: true,
      show_debug: false,
      run_filter_after_save: false,


      segments:[
        {_id: 0, segment_operator: null,
          conditions:[
              {
                _id:'c0',
                operator:'',
                course_id: null,
                student_typ_course_id: null,
                "student_typ_course": "any",
                date_compare: 'reg',
                "language_category": ["All"],
                usage_kind:'',
                language_value:'cs'
              }]
        }
      ],
      nf_actions:[{
        _id:'a0',
        kind:'',
        email_to:'',
        content:''
      }],
      destroy_conditions:[],
      destroy_segments:[],
      destroy_actions:[]
    }
  },

  computed:{

  },

  methods:{

    runFilter(){

      this.run_filter_after_save = true
      // this.$parent.selectStudents(this.notify_filter_id)
      this.saveChanges()

    },


    removeAction(act){
      console.log('act rm', act._id)
      if (act.id > 0){
        this.destroy_actions.push(act.id)
      }


      for(let i in this.nf_actions){
        if (this.nf_actions[i]._id == act._id)  this.nf_actions.splice(i, 1)
      }

    },


    removeCond(cond){



      if (cond.id>0){
        this.destroy_conditions.push(cond.id)
      }

      for(var a in this.segments){
        for(let b in this.segments[a].conditions){
          if ( this.segments[a].conditions[b]._id == cond._id) this.segments[a].conditions.splice(b,1)

        }
      }
    },

    addAction(){
      this.nf_actions.push({
        _id: uuidv4(),
        kind:'',
        email_to:'',
        content:''
      })
    },

    addSegment(){
      const seg = {
        _id: uuidv4(),
        segment_operator:"AND",
        conditions:[{_id: uuidv4(), operator:'',segment_operator:null, statuses:[]}]
      }
      this.segments.push(seg)
    },
    removeSegment(seg){
      if (seg.id>0){
        this.destroy_segments.push(seg.id)
      }
      for (let i in this.segments){
        if (this.segments[i]._id == seg._id) this.segments.splice(i,1)
      }
      this.segments[0].segment_operator = null

    },

    addCondition(seg){
      seg.conditions.push({
        _id: uuidv4(),
        operator:'',
        segment_operator:'AND',
        language_value:'cs',
        course_id:null,
        student_typ_course_id:null,
        statuses:[]
      })
    },


    saveChanges(){
      const data = {
        destroy_conditions: this.destroy_conditions,
        destroy_segments: this.destroy_segments,
        destroy_actions: this.destroy_actions,
        notify_filter:{
          active: this.filter.active,
          name: this.filter.name,
          trigger_kind: this.filter.trigger_kind,
          filter_items_a: this.segments,
          nf_actions: this.nf_actions
        }
      }

      if (this.notify_filter_id>0){
        this.updateItem(data)
      }else{
        this.createItem(data)
      }
    },

    updateItem(data){
      axios.put(`/notify_filter/${this.notify_filter_id}`, data).then(response => {
        console.log('resp', response.data)

        this.$toasted.show("Uloženo OK", {type: 'success'});

        this.loadFilter(this.notify_filter_id)

        if (this.run_filter_after_save) {
          this.run_filter_after_save = false
          this.$parent.selectStudents(this.notify_filter_id)

        }

      }).catch(err => {

        this.$toasted.show("Chyby", {type: 'error'});
        this.errors = err.response.data
      })
    },

    createItem(data){
      axios.post(`/notify_filter`, data).then(response => {
        //console.log('resp', response.data)
        this.$toasted.show("Filtr byl vytvořen", {type: 'success'});
        this.loadFilter(response.data.nf.id)
        this.filter.id = response.data.nf.id
      }).catch(err => {
       // this.$toast.error('Chyba')
        this.errors = err.response.data
      })
    },

    loadFilter(fid){
      axios.get(`/notify_filter/${fid}`).then(response => {
        this.filter.active = response.data.notify_filter.active
        this.segments = response.data.notify_filter.filter_items
        this.nf_actions = response.data.notify_filter.notify_actions
        this.notify_filter_id =  response.data.notify_filter.id
        for(var a in this.segments){
          if (!this.segments[a]._id) this.segments[a]._id = this.segments[a].id
          for(let b in this.segments[a].conditions){
            if ( !this.segments[a].conditions[b]._id) this.segments[a].conditions[b]._id = this.segments[a].conditions[b].id
            const c = this.segments[a].conditions[b]
            if (c.eshops) c.eshops= JSON.parse(c.eshops)
            if (c.statuses) c.statuses = JSON.parse(c.statuses)
            if (c.language_category) c.language_category = JSON.parse(c.language_category)

            if (b==0){
              this.segments[a].conditions[b].segment_operator=null
            }
          }
        }

        for(var i in this.nf_actions){
          if (!this.nf_actions[i]._id) this.nf_actions[i]._id = this.nf_actions[i].id
        }


      }).catch(err => {

       // this.$toast.error('Chyba')
        this.errors = err.response.data

      })
    }

  },
  mounted() {
    this.eshops.push({symbol: 'onlinejazyky', json_name: 'onlinejazyky'})
    this.loadFilter(this.filter.id)
  }

}
</script>

<style scoped lang="scss">
.segment{

  margin-bottom: 1em;
  .segment-op{
    margin-bottom: 1em;
  }
.segment-body{
  border: 1px dotted black;
  padding:1em;
  position: relative;
}
}

.remove-segment{
  display: inline-block;
  width:28px;
  height:28px;
  border:1px solid #eee;
  border-radius: 15px;
  background: #fff url('/img/delete-forever.png') 2px 2px no-repeat;
  background-size: 20px 20px;
  position: absolute;
  top:-12px;
  right:-12px;
  &:hover{
    background-color: indianred;
  }
}


.cond-wrapper{
  position: relative;
  .remove-cond{
    position: absolute;
    top:21px;
    right:0;
  }
}

</style>