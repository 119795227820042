<script>
import studentStatsMixin from './student-stats-mixin'
export default {
  name: "subscriptions-6m",
  props: ['stats', 'period'],
  mixins: [studentStatsMixin],

  /*
* {
"subs": {
  "id": 7,
  "month": "2024-09-01",
  "stopped1_count": 33,
  * * :stop6_count,
		* :stop12_count,
  "active1_count": 266,
  "active6_count": 131,
  "active12_count": 104,
  "started1_count": 32,
  "started6_count": 29,
  "started12_count": 14,
  "sum1_totals": "1608.0",
  "sum6_totals": "39974.1",
  "sum12_totals": "34540.8",
  "currency_id": 1,
  "renew6_count": 10,
  "renew12_count": 8,
  "renew6_totals": "0.0",
  "renew12_totals": "0.0",
  "average_lifetime": "2.65",
  "created_at": "2024-09-06T04:01:34.502+02:00",
  "updated_at": "2024-09-06T15:35:02.038+02:00"
}
}
* */
  methods: {

    filteredStats() {
      return this.stats.slice(0, -1);
    },
    // trendy jen šipkou kromě peněz, tam bych nechal číslo
    active_trend(s) {
      let soucasne = s.subs.active1_count
      let m = s.month
      let minuy_m = moment(m).subtract(1, 'month').format('YYYY-MM-DD')
      let minuly_mesic = this.stats.find(s => s.month === minuy_m)

      if (minuly_mesic) {
        let minuly = minuly_mesic.subs.active1_count
        if (minuly == 0) {
          return "n/a"
        }
        let trend = (soucasne - minuly) / minuly

        if (trend > 0) {
          return " ↑"
        } else {
          return " ↓"
        }

      } else {
        return "n/a"
      }
    },
    started_trend(s) {
      const p = this.period
      let soucasne = s.subs[`started${p}_count`]
      let m = s.month
      let minuy_m = moment(m).subtract(1, 'month').format('YYYY-MM-DD')
      let minuly_mesic = this.stats.find(s => s.month === minuy_m)

      if (minuly_mesic) {
        let minuly = minuly_mesic.subs[`started${p}_count`]
        if (minuly == 0) {
          return "n/a"
        }
        let trend = (soucasne - minuly) / minuly

        if (trend > 0) {
          return " ↑"
        } else {
          return " ↓"
        }

      } else {
        return "n/a"
      }
    },

    procento_obnovenych(s){
      let p = this.period
      let obnovenych = s.subs[`renew${p}_count`]
      let koncicich = s.subs[`stop${p}_count`]

      // kolik procent z koncicich se obnovilo
      let podil = obnovenych / koncicich * 100
      return Math.round(podil) + "%"


    },
    trend_procenta_obnovenych(s){
      let p = this.period
      let soucasne = s.subs[`renew${p}_count`]
      let soucasne_stop = s.subs[`stop${p}_count`]
      let m = s.month
      let minuy_m = moment(m).subtract(1, 'month').format('YYYY-MM-DD')
      let minuly_mesic = this.stats.find(s => s.month === minuy_m)

      if (minuly_mesic) {
        let minuly_r = minuly_mesic.subs[`renew${p}_count`]
        let minuly_stop = minuly_mesic.subs[`stop${p}_count`]
        if (minuly_r == 0) {
          return "n/a"
        }

        let soucasny_podil = soucasne / soucasne_stop
        let minuly_podil = minuly_r / minuly_stop

        let trend = (soucasny_podil - minuly_podil) / minuly_podil

        if (trend == 0) {
          return " →"
        }
        if (trend > 0) {
          return " ↑"
        } else {
          return " ↓"
        }

      } else {
        return "n/a"
      }
    },


    trend_sum_totals(s) {
      let p = this.period
      let soucasne = s.subs[`sum${p}_totals`]

      let m = s.month
      let minuy_m = moment(m).subtract(1, 'month').format('YYYY-MM-DD')
      let minuly_mesic = this.stats.find(s => s.month === minuy_m)

      if (minuly_mesic) {
        let minuly = minuly_mesic.subs[`sum${p}_totals`] // .sum1_totals
        if (minuly == 0) {
          return "n/a"
        }
        let trend = (soucasne - minuly) / minuly

        if (trend > 0) {
          return " ↑"
        } else {
          return " ↓"
        }

      } else {
        return "n/a"
      }
    }

  }
}
</script>

<template>
 <div class="card card-body">
    <table class="table table-bordered">
    <tr>
      <th></th>
      <th>aktivní</th>
      <th>trend</th>
      <th>nová</th>
      <th>trend</th>

      <th>končící	</th>
      <th>obnovená</th>
      <th>podíl	</th>
      <th>trend</th>
      <th>∑ plateb</th>
      <th>trend</th>
    </tr>
    <tr v-for="s in filteredStats()">
      <td>{{  mesic_z_datumu(s.month) }}</td>
      <td>{{s.subs[`active${period}_count`]}} </td>
      <td>{{active_trend(s)}}</td>

      <td>{{s.subs[`started${period}_count`]}}</td>
      <td>{{started_trend(s)}}</td>




      <td>{{s.subs[`stop${period}_count`]}}</td>
      <td>{{s.subs[`renew${period}_count`]}}</td>
      <td>{{ procento_obnovenych(s) }}</td>
      <td>{{ trend_procenta_obnovenych(s)  }}</td>

      <td>{{formatCurrency(Math.round(s.subs[`sum${period}_totals`]))}}   </td>
      <td>{{trend_sum_totals(s)}}</td>
    </tr>
  </table>
  </div>
</template>

<style scoped>

</style>