<script>
import studentStatsMixin from "./student-stats-mixin";
export default {
  name: "top-selling-table",
  props: ['top_selling'],
  mixins: [studentStatsMixin],
  methods:{
    trend(month, index){

      if (index <1 || !this.top_selling[month] || !this.top_selling[month].products || !this.top_selling[month].products[index - 1]) {
        console.log('N/A*', month, index);
        return "N/A*";
      }

      let soucasny_produkt = this.top_selling[month].products[index - 1];
      let minuly_produkt = this.top_selling[month + 1].products.find(p => p.product_id === soucasny_produkt.product_id);

      if (minuly_produkt) {
        let soucasny_mesic = soucasny_produkt.total;
        let minuly_mesic = minuly_produkt.total;
        return (100 * (soucasny_mesic - minuly_mesic) / soucasny_mesic).toFixed(1);
      } else {
        return "N/A"; // nebo jiná hodnota, pokud produkt v předchozím měsíci neexistuje
      }
    },
    getProduct(month, index) {
      if (this.top_selling[month] && this.top_selling[month].products && this.top_selling[month].products[index - 1]) {
        return this.top_selling[month].products[index - 1];
      } else {
        return { name: '-', orders_count: 0, total: 0 };
      }
    }
  }

}
</script>

<template>
  <table class="table" v-if="top_selling[0]">
    <tr>
      <td></td>
      <th colspan="4">současný měsíc	</th>
      <th colspan="4">minulý měsíc	</th>
      <th colspan="4">  předminulý měsíc</th>
    </tr>

    <tr>
      <td></td>
      <th>Produkt</th>
      <th>počet</th>
      <th>suma</th>
      <th class="border-right">trend</th>
      <th>Produkt</th>
      <th>počet</th>
      <th>suma</th>
      <th class="border-right">trend</th>
      <th>Produkt</th>
      <th>počet</th>
      <th>suma</th>
      <th>trend</th>
    </tr>

    <tr v-for="index in 10" :key="index">
      <td>{{index}}</td>
      <td>{{getProduct(0, index).name}}</td>
      <td>{{getProduct(0, index).orders_count}}</td>
      <td>{{ formatCurrency(getProduct(0, index).total)}}</td>
      <td></td>

      <td>{{getProduct(1, index).name}}</td>
      <td>{{getProduct(1, index).orders_count}}</td>
      <td>{{formatCurrency(getProduct(1, index).total)}}</td>
      <td>{{trend(1,index)}}</td>

      <td>{{getProduct(2, index).name}}</td>
      <td>{{getProduct(2, index).orders_count}}</td>
      <td>{{formatCurrency(getProduct(2, index).total)}}</td>
      <td>{{trend(2,index)}}</td>
    </tr>
  </table>

</template>

<style scoped>

</style>