
import Vue from 'vue/dist/vue.esm'

export function add_vueApp2(id , component){
    const ele = document.getElementById(id)
    if (ele){
        const a = new Vue(component)
        for(let i in ele.dataset){
            a[i] = ele.dataset[i]
        }
        a.$mount('#'+id);
    }
}
