<template>
<vue-final-modal v-model="showM" classes="modal-container" content-class="modal-content-f w800">
    <div class="modal-header">
      <b>Detail studenta</b>
        <button type="button" class="close" @click.prevent="hideModal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>

    </div>

    <div class="modal__content">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Jméno</label>
            <input type="text" v-model="student.name" class="form-control" />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>příjmení</label>
            <input type="text" v-model="student.surname" class="form-control" />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label>přezdívka</label>
            <input type="text" v-model="student.nickname" class="form-control" />
          </div>
        </div>

      </div>
      <div class="row">

        <div class="col-md-6">
          <div class="form-group">
            <label>e-mail</label>
            <input type="text" v-model="student.email" class="form-control" :readonly="!admin"/>
          </div>
        </div>


        <div class="col-md-6">
          <div class="form-group">
            <label>telefon</label>
            <input type="text" v-model="student.tel" class="form-control" />
          </div>
        </div>

      </div>
      <div class="row" v-if="student.id == undefined || student.id == null ">
        <div class="col-md-12 form-group">

          <label><input type="checkbox" v-model="student.change_password_helper"/>  Vynutit změnu hesla</label>
        </div>
        <div class="col-md-6 form-group">
          <label>Heslo</label>
          <input type="text" v-model="student.password_helper" class="form-control" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Datum platnosti</label>
            <div v-if="admin">
                 <input type="date" v-if="maual_edit_datum"
                     v-model="student.cc_until"
                      class="form-control"
                 /><span v-else>{{ df(student.kk_expirace)}}  <a @click.prevent="maual_edit_datum=true" href="#" class="small" style="top:5px; position:relative"><span class="material-icons-outlined">mode_edit_outline</span></a></span>
            </div>
            <div v-else>
              {{ df(student.kk_expirace)}}
            </div>
          </div>
        </div>

        <div class="col-md-6 form-group">
          <label>Firma</label>
          <select v-model="student.organization_helper" class="form-control" v-if="admin">
            <option v-for="o in organizations" :value="o.id">{{o.name}}</option>
          </select>
          <div v-else>
            {{student.organization_name}}
          </div>
        </div>

        <div class="col-md-6">
          <p>Limit počtu rezervací týdně:
            <input type="number" v-if="admin" v-model="student.kk_week_limit" class="form-control w-auto" size="6" />
            <span v-else>{{student.kk_week_limit }}</span>
          </p>
        </div>

      </div>
      <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label style="text-transform: none"><input type="checkbox" v-model="student.kk_email_notifications"/> Zasílat potvrzení do e-mailu, že došlo k rezervaci nebo ke zrušení rezervace</label>
                </div>
              </div>
      </div>

    </div>
  <div class="modal__action">
    <button  class="btn btn-primary" @click="handleSaveChanges">uložit změny</button>
    <button class="btn btn-outline-dark ml-4" @click="showM=false">zrušit</button>
  </div>
</vue-final-modal>

</template>

<script>
import axios from "axios";
import {VueFinalModal} from "vue-final-modal";
import modalKkMixin from "./modal-kk-mixin";
import MiniCalendar from "./mini-calendar.vue";
export default {
  name: "modal-kk-student"  ,
  components: {MiniCalendar, VueFinalModal },
  props:['student', 'admin'],
  mixins:[modalKkMixin],
  data(){
    return({
      maual_edit_datum:false,
        organizations:[]
    })
  },
  mounted() {
    this.loadOrganizations()
  },
  watch:{
    student(){
      this.maual_edit_datum = false
      if (this.student.memberships && this.student.memberships.length>0){
        this.student.organization_helper = this.student.memberships[0].organization_id
      }

      const cc_subs_until = moment(this.student.cc_subs_until ).unix() || 0
      const cc_until= moment(this.student.cc_until).unix() || 0
      if (cc_subs_until > cc_until) this.student.cc_until = this.student.cc_subs_until

    }
  },
  methods:{
    loadOrganizations(){
      axios.get('/conversation_club/organizations.json',{}).then(response =>{
        this.organizations = response.data.organizations
      })
    },
    handleSaveChanges(){
      if (this.student.id>0){

        axios.put(`/conversation_club/students/${this.student.id}`, { student: this.student }).then(response =>{
          this.$toasted.show("Změny uloženy", {type: 'success'});
          this.$emit('userUpdated')
          this.hideModal()
        })

      }else{
        this.createStudent()
      }
    },

    createStudent(){
      const formData = { student: this.student }
      axios.post('/conversation_club/students/create', formData).then(response =>{
        this.$emit('userUpdated')
        this.$toasted.show("Změny uloženy", {type: 'success'});
        this.hideModal()
      })
    }
  }
}
</script>

<style scoped>

</style>