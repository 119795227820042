// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"

// JS - bootstrap
import "bootstrap"

// JS - Font Awesome icons
import "@fortawesome/fontawesome-free/js/all"

// all things css
import "stylesheets"

//  3rd party / other code
import "lib"

// Bootstrap 4 datetime picker https://tempusdominus.github.io/bootstrap-4/
// requires moment.js package, imported as a webpacker plugin in /config/webpack/environment
//import "tempusdominus-bootstrap-4"


import './vue_components'
import "../components/statistics/init"



global.batchFillDateExpiration = function(){
  var v = document.getElementById('batch-date-preset').value
  $('.row-course').each(function(row){
    var c = $(this).find('.col-batch-cb input').is(':checked')

    if (c){
      $(this).find('.col-expiration .expiration-input').val(v)
    }
  })

}
