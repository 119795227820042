$(document).ready(function () {
    function updateUrlWithParams(url, params) {
        var query;

        // split query from url
        var pos = url.indexOf('?');
        if (pos > 0) {
            query = url.substring(pos + 1);
            url = url.substr(0, pos);
        } else {
            query = "";
        }

        // parse query into an object
        var parsed_query = {};
        query.replace(/&*([^=&]+)=([^=&]*)/gi, function(str,key,value) {parsed_query[decodeURIComponent(key)] = decodeURIComponent(value)});

        // update query with params
        $.each(params, function (i, param) {
            parsed_query[param[0]] = param[1];
        });

        // create new URL
        var sep = '?';
        $.each(parsed_query, function (k, val) {
            url += sep + encodeURIComponent(k) + '=' + encodeURIComponent(val);
            sep = '&'
        });

        return url;
    }

    // timeout used so URL is not updated too often on typing
    var pushStateTimeout = null;

    // url that represents the last known state
    var currentStateUrl = location.href;

    // currently running AJAX
    var currentXhr = null;

    $('.listing').each(function () {

        console.log("listing init ", $(this).data("name"));
        var $listing = $(this);
        var $filters = $listing.find('.filters');
        var $table = $listing.find('.table');
        var $pages = $listing.find('.pages');
        var name = $listing.data('name');

        // factory for success function
        var updateFn = function (delayedPush) {
            return function (data) {
                // update html
                $table.html(data.table);
                $pages.html(data.pages);

                // update current state according to params sent from Rails
                currentStateUrl = updateUrlWithParams(location.href, data.params);

                // push state to history
                if (pushStateTimeout) clearTimeout(pushStateTimeout);
                if (delayedPush) {
                    pushStateTimeout = setTimeout(function () {history.pushState(location.href, "listing", currentStateUrl)}, 300);
                } else {
                    history.pushState(location.href, "listing", currentStateUrl)
                }

                $listing.trigger('update');
            }
        };

        // makes AJAX request and handles the response
        var doRequest = function (url, delayedPush) {
            if (currentXhr) currentXhr.abort();

            currentXhr = $.ajax(url, {
                method: 'GET',
                dataType: 'json',
                success: updateFn(delayedPush),
                error: function (xhr, status, error) {
                    if (status != "abort") {
                        console.log("AJAX failed:", xhr, status, error);
                        window.location = url;
                    }
                }
            });
        };

        // handle paging links
        $pages.on('click', 'a[href]', function () {
            doRequest(updateUrlWithParams(currentStateUrl, [["listing", name], [name + "[page]", $(this).data('page')]]));
            return false;
        });

        // handle sorting links
        $table.on('click', 'a.sort', function () {
            doRequest(updateUrlWithParams(currentStateUrl, [["listing", name], [name + "[sort]", $(this).data('sort')]]));
            return false;
        });

        // handle filters
        var filterTimeout = null;

        $filters.on('submit', 'form', function () {return false;}); // prevent manual form submit

        var submitFilters = function () {
            // collect form data as params
            var data = [];
            $.each($filters.find('form').serializeArray(), function (i, o) {
                if (o.name.substr(0, name.length + 8) == name + '[filter]') {
                    data.push([o.name, o.value])
                }
            });
            data.push(['listing', name]);
            data.push([name + '[page]', 1]);

            // send
            doRequest(updateUrlWithParams(currentStateUrl, data), true);
        };

        $filters.on('input', 'input[type=text]', function () {
            if (filterTimeout) clearTimeout(filterTimeout);
            filterTimeout = setTimeout(submitFilters, 200);
        });
        $filters.on('change', 'select', submitFilters);
    });

    $(window).on('popstate', function (e) {
        location.reload()
    })
});