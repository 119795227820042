<template>
  <!--  testovaci karta 5447380000000006 -->
  <div class="container">

    <div class="d-flex" style="justify-content: center">
      <img style="height:200px; margin-top: 20px; margin-bottom: 20px" src="/img/OJ_Logo.svg"/>
    </div>

    <div class="item blue" v-if="step==2">

      <div style="padding-bottom: 50px; padding-left: 10px">
        <h3>Vaše objednávka</h3>
      </div>

      <product-table-kk :items="items" :cart="cart" @changeProduct="changeProduct"
                        v-if="cart.table_template == 'kk'"></product-table-kk>
      <product-table-default :items="items" :cart="cart" @changeProduct="changeProduct" v-else></product-table-default>

    </div>



    <div class="blue">

      <template v-if="step==1">
      <div class="title-data">
        <h3>Vaše údaje</h3>
      </div>

      <div class="col-md-11" style="padding-left: 48px;">
        <hr>
      </div>

      </template>

      <div class="item">
       <billing-fields v-if="step==1" @next-step="handleStep1Submitted"></billing-fields>


        <div class="shoping-cart-step-2" style="padding-bottom: 50px; padding-left: 10px;" v-if="step==2">

          <div class="wcf-coupon-col-12" style="margin-bottom: 15px">
            <label for="billing_email" class="">Slevový kupón&nbsp;<abbr class="required" title="vyžadováno"> </abbr></label>

            <input
                type="text"
                v-model="discount_code"
                class="input-code"
                placeholder="Kód kupónu"
                id="coupon_code"
                value=""
            />

            <button :disabled="!codeIsFilled" type="button" class="code-button" @click.prevent="applyCouponCode">
              Použít
            </button>
          </div>

<!--          <div class="col-md-12">-->
<!--            <hr>-->
<!--          </div>-->

          <div class="d-flex" style="margin-bottom:40px;">
            <div style="width: 20px">
              <input type="checkbox" class="checkbox_input" id="conditions-checkbox" v-model="checked"/>
            </div>
            <div>
              <label for="conditions-checkbox">Přečetl/-a jsem </label>
            </div>
            <div>
              <a href="https://www.onlinejazyky.cz/smluvni-podminky.html" target=”_blank”>Obchodní podmínky</a><br>
            </div>
          </div>

          <div class="row">
            <div>
              <button :disabled="!fieldsAreFilled||!checked" @click.prevent="handle_create_order">Přejít k platbě
              </button>
            </div>
          </div>

        </div><!-- shoping-cart-step-2 -->



      </div><!-- item -->
    </div>

  </div>


  <!--    <set-password></set-password> -->


  <!--  <div>-->
  <!--    <div class="d-flex shopping-cart">-->
  <!--      <div class="ml-auto" style="width: 555px">-->
  <!--        <div class="logo">-->
  <!--          <img src="/img/logo-el.svg" />-->
  <!--        </div>-->

  <!--        <div class="hh">Vaše objednávka</div>-->
  <!--        <table class="table ">-->
  <!--          <thead>-->
  <!--            <tr>-->
  <!--              <th colspan="2">Produkt</th>-->
  <!--                <th></th>-->
  <!--              <th>Mezisoučet</th>-->
  <!--            </tr>-->
  <!--          </thead>-->
  <!--          <tr v-for="it in items">-->
  <!--              <td>-->
  <!--                  <a class="remove-item">x</a>-->
  <!--              </td>-->
  <!--            <td>-->
  <!--              <img :src="it.product.small_image_url" class="product-image" />-->
  <!--            </td>-->
  <!--            <td>{{ it.product.name }}</td>-->
  <!--            <td>{{ it.product.price_czk }}</td>-->
  <!--          </tr>-->


  <!--            <tr>-->
  <!--                <th colspan="3">Cena celkem</th>-->

  <!--                <th>2 490 Kč</th>-->
  <!--            </tr>-->
  <!--        </table>-->

  <!--          <div class="extra-blok">-->
  <!--              <div class="sipka"></div>-->
  <!--              <div class="extrablok-body">-->
  <!--                <div class="chci-cbb">-->
  <!--                  <cb-gr />  Chci navíc předplatné konverzačních klubů angličtiny jen za 490 Kč-->
  <!--                </div>-->


  <!--                  <img data-v-16670798="" src="https://www.onlinejazyky.cz/files/mod_eshop/produkty/nahled/234-anglictina-pro-zacatecniky-a1.png" class="product-image">-->
  <!--                  Angličtina pre pokročilých-->
  <!--              </div>-->

  <!--          </div>-->


  <!--      </div>-->


  <!--      <div class="meziblok">-->

  <!--      </div>-->

  <!--      <div class="mr-auto right-block" style="width: 720px; ">-->
  <!--        <div id="shoping-cart-step-1" v-if="!payment_id">-->
  <!--          <div class="billing-fields row">-->
  <!--            <div class="col-md-12">-->
  <!--              <div class="hh">Fakturační údaje</div>-->
  <!--            </div>-->

  <!--              <div class="col-md-12">-->

  <!--                  <label for="billing_email" class="">Email&nbsp;<abbr class="required" title="vyžadováno">*</abbr></label>-->
  <!--                  <span class="woocommerce-input-wrapper"><input     type="email"   class="input-text"      placeholder=""  v-model="billing_email" autocomplete="email username" /></span>-->

  <!--              </div>-->

  <!--              <div class="col-md-6"   id="billing_first_name_field">-->
  <!--                  <label for="billing_first_name" class="">Jméno&nbsp;<abbr class="required" title="vyžadováno">*</abbr></label>-->
  <!--                  <span class="woocommerce-input-wrapper"><input type="text" class="input-text"  v-model="billing_first_name"    autocomplete="given-name" /></span>-->
  <!--              </div>-->

  <!--              <div class="col-md-6"  id="billing_last_name_field">-->
  <!--                <label for="billing_last_name" class="" >Příjmení&nbsp;<abbr class="required" title="vyžadováno" >*</abbr></label >-->
  <!--                  <input   type="text"  class="input-text"  v-model="billing_last_name" placeholder="" autocomplete="family-name"  />-->
  <!--              </div>-->

  <!--            </div>-->

  <!--            <div class="text-center mt-3">-->
  <!--              <a href="#" @click.prevent="company_box=!company_box">Koupit na firmu</a>-->
  <!--            </div>-->

  <!--            <div class="row" v-if="company_box">-->
  <!--                    <div class="col-md-12" id="billing_company_field" data-priority="">-->
  <!--                        <label for="billing_company" class="screen-reader-text"-->
  <!--                        >Společnost&nbsp;<span class="optional"-->
  <!--                        >(volitelný)</span-->
  <!--                        ></label><span class="woocommerce-input-wrapper" ><input type="text"  class="input-text"  v-model="billing_company"  id="billing_company"  placeholder="Společnost"  value=""/></span>-->
  <!--                    </div>-->

  <!--                    <div class="col-md-6"               id="billing_ic_field"            data-priority="31" >-->
  <!--                        <label for="billing_ic" class="">IČO&nbsp;<span class="optional">(volitelný)</span></label>-->
  <!--                        <span class="woocommerce-input-wrapper"><input  type="text" class="input-text" v-model="billing_ic"  placeholder="IČO" /></span>-->
  <!--                    </div>-->
  <!--                    <div class="col-md-6" id="billing_dic_field" data-priority="31">-->
  <!--                      <label for="billing_dic" class="">DIČ&nbsp;<span class="optional">(volitelný)</span></label>-->
  <!--                        <span class="woocommerce-input-wrapper"><input type="text" class="input-text" v-model="billing_dic" id="billing_dic"  placeholder="DIČ" value=""/></span>-->
  <!--                    </div>-->

  <!--                    <div class="col-md-6" id="billing_dic_dph_field" data-priority="31">-->
  <!--                      <label for="billing_dic_dph" class="">IČ DPH&nbsp;<span class="optional">(volitelný)</span></label><span class="woocommerce-input-wrapper"-->
  <!--                        ><input  type="text" class="input-text" v-model="billing_dic_dph" placeholder="IČ DPH" /></span>-->
  <!--                    </div>-->
  <!--            </div>-->


  <!--          <div class="row">-->

  <!--              <div class="col-md-12">-->
  <!--                  <hr>-->
  <!--              </div>-->

  <!--            <div class="wcf-coupon-col-1 col-md-6">-->

  <!--                <input-->
  <!--                  type="text"-->
  <!--                  v-model="discount_code"-->
  <!--                  class="input-text wcf-coupon-code-input"-->
  <!--                  placeholder="Kód kupónu"-->
  <!--                  id="coupon_code"-->
  <!--                  value=""-->
  <!--                />-->

  <!--            </div>-->
  <!--            <div class="col-md-6">-->
  <!--              <span>-->
  <!--                <button          type="button"-->
  <!--                                 class="button-usecode "     @click.prevent="applyCouponCode"   >-->
  <!--                  Použít  <img src="/img/_arrow-right.svg" style="height: 25px; position: relative; top:-2px;"/>-->
  <!--                </button>-->
  <!--              </span>-->
  <!--            </div>-->

  <!--              <div class="col-md-12">-->
  <!--                  <hr>-->
  <!--              </div>-->


  <!--              <div class="hh">-->
  <!--                  Platba kartou (GoPay)-->
  <!--              </div>-->

  <!--          </div>-->

  <!--          <button @click.prevent="handle_create_crder">Objednat</button>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->

  <!--&lt;!&ndash;    <set-password></set-password> &ndash;&gt;-->
  <!--  </div>-->

</template>

<script>
import axios from "axios";
import SetPassword from "./set-password.vue";
import CbGr from "./cb-gr.vue";
import ProductTableKk from "./product-table-kk.vue";
import ProductTableDefault from "./product-table-default.vue";
import BillingFields from "./billing-fields.vue";

export default {
  props: ["objid"],
  components: { BillingFields, ProductTableDefault, ProductTableKk, SetPassword, CbGr},
  name: "shopping-cart",
  data() {
    return {
      step: 1,
      company_box: false,
      items: [],
      billing: {
        email: "",
        first_name: "",
        last_name: "",
        ic: "",
        dic: "",
        dic_dph: "",
        company: "",
      },

      discount_code: "",
      payment_id: null,
      currency: 'CZK',

      order_id: null,
      cart: {},
      iframe_gopay_url: null,
      paymentSessionId: null,
      encryptedSignature: null,
      checked: false,
    };
  },
  computed: {
    fieldsAreFilled() {
      return this.billing.email && this.billing.first_name && this.billing.last_name
    },
    codeIsFilled() {
      return this.discount_code
    }
  },

  mounted() {
    this.loadCart();
  },
  methods: {
    handleProductAdded() {

      this.loadCart()
    },

    handleStep1Submitted(billing){
      this.billing = billing;
      this.step = 2;
    },

    changeProduct(it) {
      console.log('change product', it, it.selected_variant)
      axios.put(`/shopping_cart/change_product/${it.id}`, {
        product_id: it.selected_variant,
        objid: this.objid
      }).then((response) => {
        this.loadCart()
      })
    },

    loadCart() {
      axios.get(`/shopping_cart/cart`, {params: {objid: this.objid}}).then((response) => {
        this.items = response.data.items;
        this.cart = response.data.cart;
      });
    },

    applyCouponCode() {
      const data = {
        code: this.discount_code,
        objid: this.objid,
        currency: this.currency
      }
      axios.post('/shopping_cart/discount-code', data).then((response) => {
        if (response.data.code && response.data.code.id > 0) {
          this.loadCart()
          this.$toasted.success("Kupón byl úspěšně použit", {duration: 5000});
        } else {
          this.$toasted.error("Neplatný kupón", {duration: 5000});
        }
      }).catch((error) => {
        //console.log(error);
        this.$toasted.error("Neplatný kupón", {duration: 5000});
      });
    },

    handle_create_order() {
      const data = {
        billing: this.billing,
        discount_code: this.discount_code,
        objid: this.objid,
      };
      axios.post(`/shopping_cart/create-order`, data).then((response) => {
        this.payment_id = response.data.payment.id;
        this.paymentSessionId = response.data.gopay_payment_session_id;
        this.encryptedSignature = response.data.encrypted_signature;
        const url = response.data.gopay_url
        if (url) {
          //window.location.href = url;
          this.iframe_gopay_url = url
          window._gopay.checkout({gatewayUrl: url, inline: true});
        }
      }).catch((error) => {
        console.log(error);
        this.$toasted.error("Chyba při vytváření objednávky - " + error.response.data.error, {duration: 5000});
      });
    },
  },
};
</script>

<style scoped lang="scss">

@media only screen and (max-width: 768px) {
  label {
    text-align: right;
  }
}

label {
  width: 100px;
  text-align: right;
  display: inline-flex;
}

.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.item {
  flex-grow: 1;
  padding: 10px 10px 0px 10px;
  padding: 50px;
}

.title-data {
  padding-left: 48px;
  padding-top: 40px;
}

.itemFU1 {
  flex-grow: 1;
  padding: 10px 10px 10px 13px;
}

.itemFU2 {
  flex-grow: 1;
  padding: 10px 10px 10px 0px;
}

input {
  background-color: white;
  width: 200px;
}

input:focus {
  outline: none !important;
  border-color: #37A8D6;
  border-style: solid;
  box-shadow: 0 0 2px #37A8D6;
}

.input-code {
  width: 120px;
}

.code-button {
  background-color: #37A8D6;
  border-radius: 20px;
  border: none;
  padding: 5px 20px 5px 20px;
  height: 28px;
  width: 80px;
  color: white;
  text-align: center;
  font-family: 'Proxima Nova', sans-serif;
  font-size: 0.8rem;
  //font-weight: bold;
  //text-transform: uppercase;
  margin-left: 10px;
  padding: 0px 9px 0px 9px;
}

button {
  background-color: #37A8D6;
  color: white;
  font-size: 1rem;
  border-radius: 20px;
  border: none;
  padding: 5px 20px 5px 20px;
  margin-left: 5px;
  //text-transform: uppercase;
  font-weight: bold;
}

button:disabled,
button[disabled] {
  background-color: lightgrey;
  color: white;
}

select {
  padding: 3px 7px 3px 7px;
}

input[type="checkbox"] {
  width: 10px;
  margin-left: 4px;
}

.shad {
  box-shadow: 0 2px 10px 8px lightgrey;
}

.blue {
  background-color: #d8f9ff;
}

</style>
