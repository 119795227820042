<template>
 <div>

  <div class=" row pb-3">
    <div class="col-md-2">
    <select class="form-control" v-model="kind" >
      <option value="all">všechny objednávky</option>
      <option value="paid">zaplacené objednávky</option>
    </select>
    </div>
    <div class="col-md-10">

      <label v-for="e in this.local_all_eshops" class="eshop" :key="e.code">
        <input type="checkbox" v-model="e.show" value="1"  @change="reloadMarketing"/>{{e.json_name}} (<b>{{e.invoice_prefix}}</b>)
      </label>
    </div>
  </div>


   <div class="card">
      <div class="card-body">
        <vue-tabs v-model="tabName">
          <v-tab title="Denní objednávky">
            <h3 class="mt-3">Denní  objednávky</h3>

         <!--   u zobrazení objednávek v jednotlivých dnech by bylo fajn tam mít filtr
         abych si mohla nastavit konkrétní rozmezí dat
         od kdy do kdy se mi dny mají zobrazit (třeba i rok zpětně v těch konkrétních dnech).
            -->

            <div class="row">
                <div class="col-md-5">
                  <div class="form-group" >
                    <label>Datum </label>
                    <div class="d-flex">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">od </span>
                        </div>
                        <input type="date" class="form-control"
                               v-model="dod" />
                      </div>
                      <div class="input-group ml-2">
                        <div class="input-group-prepend">
                          <span class="input-group-text">do </span>
                        </div>
                        <input type="date" class="form-control"
                               v-model="ddo" />
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div v-if="d_loading" class="text-center mt-auto">
              <img src="/img/1495.gif" />
            </div>
            <table class="table table-bordered" v-if="ddata.length>0 && !d_loading">
              <tr>
                <td >datum</td>
                <th v-for="e in eshops">{{e.invoice_prefix}}</th>
              </tr>

              <template v-for="row in ddata">
                <tr>
                  <td class="datum" rowspan="2">{{row.day.substring(5)}} </td>
                  <td v-for="e in row.eshops" >{{ e.czk | decimal }}</td>
                  <th><span >{{row.czk | decimal}}&nbsp;Kč</span></th>
                </tr>
                <tr class="line2">
                  <td v-for="e in row.eshops" >{{e.eur | decimal}}</td>
                  <th><span >{{row.eur | decimal}}&nbsp;&euro;</span></th>
                </tr>
              </template>
            </table>

            <div>
            </div>

            <div  class="row">
              <div class="col-md-9 text-center">
                <button class="btn btn-outline-dark" @click.prevent="load_more">načíst dřívější</button>

              </div>

              <div class="col-md-3">
                <div class="pagination full">
                  <div class="per-settings">
                    <span>Položek:</span>
                    <button :class="perpage == 10 ? `active`: ''" @click.prevent="perpage=10">10</button>
                    <button :class="perpage == 20 ? `active`: ''" @click.prevent="perpage=20" >20</button>
                    <button :class="perpage == 50 ? `active`: ''"  @click.prevent="perpage=50">50</button>
                    <button :class="perpage == 100 ? `active`: ''"  @click.prevent="perpage=100">100</button>
                  </div>

                </div>
              </div>
            </div>





          </v-tab>

          <v-tab title="Měsíční  objednávky">
            <h3 class="mt-3">Měsíční  objednávky</h3>

            <div v-if="m_loading" class="text-center mt-auto">
              <img src="/img/1495.gif" />
            </div>

            <div class="row mb-2">
              <div class="col-md-2 ">
                <select v-model="year" class="form-control">
                  <option v-for="r in roky">{{r}}</option>
                </select>
              </div>
            </div>

            <table class="table table-bordered">

              <tr>
                <td></td>
                <th v-for="e in eshops">{{e.invoice_prefix}}</th>
              </tr>

              <template v-for="row in mdata">
                <tr>
                  <td rowspan="2">{{row.month}} </td>
                  <td v-for="e in row.eshops">{{e.czk | decimal }}</td>
                  <th>{{row.czk | decimal }} Kč</th><td><a @click.prevent="showDetail(row)">detail</a></td>
                </tr>
                <tr>
                  <td v-for="e in row.eshops">{{e.eur | decimal }}</td>
                  <th>{{row.eur | decimal }} &euro;</th>
                </tr>
              </template>

              <tr>
                <th>
                  Celkem czk
                </th>
                <td v-for="e in eshops">{{get_sum_eshop(e).czk | decimal }}</td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <th>
                  Celkem eur
                </th>
                <td v-for="e in eshops">{{get_sum_eshop(e).eur | decimal }}</td>
                <td colspan="2"></td>
              </tr>
            </table>
          </v-tab>
        </vue-tabs>

      </div>
   </div>

   <marketing-detail ref="marketingDetail" :row="current_row" :eshops="eshops"></marketing-detail>
</div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import OjMixin from "./oj-mixin";
import moment from "moment/moment";
import MarketingDetail from "./marketing-detail.vue";

export default {
  name: "marketing",
  components: {MarketingDetail},
  props:['all_eshops'],
  mixins:[OjMixin],
  watch:{
    dod(){
      this.loadDData()
    },
    ddo(){
      this.loadDData()
    },
    kind(){
      //this.loadMData()
      //this.loadDData()

      if (this.tabn() == 'd')
        this.loadDData()
      else
        this.loadMData()

    },
    year(){
      this.loadMData()
    },
    perpage(){
      this.loadDData()
    },
    tabName(){
      this.d_reloadMarketing(this)
    }
  },

  computed:{
    roky(){
      let date =  new Date().getFullYear();
      const y = []
      for(let i=0; i<5; i++){
        y.push(date - i)
      }
      return y
    },
    selected_eshop_codes_arr(){
      let arr = []
      for(var i in this.local_all_eshops){
        if (this.local_all_eshops[i].show){
          arr.push(this.local_all_eshops[i].symbol)
        }
      }
      return arr
    },
    selected_eshop_codes(){
      return this.selected_eshop_codes_arr.join(',')
    },


    lastDay(){
      if (this.ddata.length<1) return null
      let d = this.ddata[this.ddata.length-1]
      return d.day
    }



  },

  data(){
    return {
      eshop_sum:{},
      dod: null,
      ddo: null,
      tabName: 'Denní  objednávky',
      perpage:10,
      startDay:null,
      eshops:[],
      mdata:[],
      ddata:[],
      d_loading: true,
      m_loading: true,
      kind: 'paid',
      local_all_eshops:[],
      year: new Date().getFullYear(),
      current_row: null
    }
  },


  mounted() {

    let selected_arr=[]
    if (localStorage.eshopsFilter){
       selected_arr = localStorage.eshopsFilter.split(',')
    }

    for (let i in this.all_eshops){
      let e = this.all_eshops[i]
      let c ={
          symbol: e.symbol,
          json_name: e.json_name,
          id: e.id,
        invoice_prefix: e.invoice_prefix,
          show: (selected_arr.indexOf(e.symbol)>=0) || selected_arr.length==0
      }

      this.local_all_eshops.push(c)

    }

    //this.loadMData()
    this.loadDData()

  },
  methods:{
    tabn(){
      if ( this.tabName == 'Denní objednávky'){
        return 'd'
      }else
      {
        return 'm'
      }
    },

    get_sum_eshop(e){

      //eshop_sum[e.symbol].czk

      if (!this.eshop_sum[e.symbol]) return 0
      return this.eshop_sum[e.symbol]
    },


    showDetail(row){

      console.log('showDetail', row)
      this.current_row = row

      this.$refs.marketingDetail.showModal()
    },

    load_more(){

      this.loadDData()

    },

    isSelected(e){
      const codes = this.selected_eshop_codes_arr
      if (e.eshop &&  codes.indexOf(e.eshop) >=0 ) return true
      if (e.symbol &&  codes.indexOf(e.symbol) >=0  ) return true
      return false
    },

    reloadMarketing(){
      localStorage.eshopsFilter = this.selected_eshop_codes
      this.startDay=null
      this.ddata=[]

      if (this.tabn() == 'd')
        this.loadDData()
      else
        this.loadMData()
    },

    d_reloadMarketing: _.debounce((vm) => {

      if (vm.tabn() == 'd')
        vm.loadDData()
      else
        vm.loadMData()

      //vm.loadMData()
      //vm.loadDData()

    }, 400),

    loadMData(){
      const that = this
      this.mdata =[]

      this.m_loading=true

      axios.get(`/marketing/orders_mdata`, {params:{ kind: this.kind, y: this.year, eshops: this.selected_eshop_codes}}).then(response => {
        this.mdata = response.data.month_data
        this.eshops = response.data.eshops
        this.calculate_m_suma()
        this.m_loading=false
      })
    },

    calculate_m_suma(){

      this.eshop_sum={}
      for (let i in this.mdata){
        let row = this.mdata[i]

        for (let j in row.eshops){
          let k = row.eshops[j].eshop
          if (!this.eshop_sum[k]) this.eshop_sum[k] = {czk:0, eur:0}
          this.eshop_sum[k].czk += parseFloat(row.eshops[j].czk)
          this.eshop_sum[k].eur += parseFloat(row.eshops[j].eur)
        }
      }

    },


    loadDData(){
      this.d_loading=true
      axios.get(`/marketing/orders_ddata`, {
        params:{
          kind: this.kind,
          eshops: this.selected_eshop_codes,
          per: this.perpage,
          start: this.startDay,

          date_from: this.dod,
          date_to: this.ddo

        }
      }).then(response => {

        this.eshops = response.data.eshops
        //this.ddata = response.data.days_data
        for (let i in response.data.days_data){
          const d = response.data.days_data[i]
          if (!this.ddataUpdateItem(d)) this.ddata.push(d)
          let den = moment(d.day)
          this.startDay = den.startOf('day').add(-1, 'days').format('YYYY-MM-DD')

        }
        this.d_loading=false
      })
    },
    ddataUpdateItem(d){

      for(let i in this.ddata){

        if (this.ddata[i].day == d.day){

          this.ddata[i] = d
          return true
        }

      }
      return false
    }

  }
}
</script>

<style scoped lang="scss">
.table-bordered {

tr.line2 {

td, th {
  border-bottom-color: black
}

}
}

td.datum{
  vertical-align: middle;
  border-bottom-color: black
}

label.eshop{
  margin: 5px 10px;
  background: white;
  padding:1px 4px;
  border-radius: 4px;
}





.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pagination button[disabled] {

    opacity: 0.2;

}
.pagination button {
  border-radius: 3px;
  border: none;
  background-color: #fff;
  padding: 0 4px;
  margin: 0;
  box-sizing: border-box;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  outline: none;
  transition: 0.2s all;
}

.pagination button.active {

    background-color: #667a98;
    color: #fff;

}

</style>