<script>
import studentStatsMixin from "./student-stats-mixin";
import axios from "axios";

export default {
  name: "purchasetype-table",
  props: ['stats'],
  mixins: [studentStatsMixin],
  /*
  * stats: [
    {
      "date": "2024-08-20",
      "one_time_order": 53,
      "subscription": 108,
      "renewal": 128,
      "nevim": 0
    },
    {
      "date": "2024-07-20",
      "one_time_order": 239,
      "subscription": 485,
      "renewal": 499,
      "nevim": 0
    },
  * */
  mounted() {

  },

  methods: {
      celkovy_trend(m){

        // aktualni mesic neukazovat - nekompletni mesic nesmyslne udaje
        if (m.date == moment().format('YYYY-MM-DD')) {
          return "🤔"
        }


        // =((H65-H66)/H65)
        // vypocitat z predchoziho mesice
        let predchozi_mesic = this.stats.find((s) => s.date == moment(m.date).subtract(1, 'months').format('YYYY-MM-DD'))
        if (predchozi_mesic == undefined) {
          return 0
        }
        let celkem = m.renewal.count+ m.subscription.count + m.one_time_order.count
        let predchozi_celkem = predchozi_mesic.renewal.count+ predchozi_mesic.subscription.count + predchozi_mesic.one_time_order.count
        return Math.round(((celkem - predchozi_celkem) * 100/ celkem))
      },
      podil_vsech_predplatnych(m){
        let suma_vsech_predplatnych = m.renewal.sum + m.subscription.sum
        let celkem = m.renewal.sum + m.subscription.sum + m.one_time_order.sum
        return Math.round(suma_vsech_predplatnych * 100 / celkem)
      },
      trend_sum_vsech_predplatnych(m){
        // aktualni mesic neukazovat - nekompletni mesic nesmyslne udaje
        if (m.date == moment().format('YYYY-MM-DD')) {
          return "🤔"
        }


        let predchozi_mesic = this.stats.find((s) => s.date == moment(m.date).subtract(1, 'months').format('YYYY-MM-DD'))
        if (predchozi_mesic == undefined) {
          return 0
        }
        let suma_vsech_predplatnych = m.renewal.sum + m.subscription.sum
        let predchozi_suma_vsech_predplatnych = predchozi_mesic.renewal.sum + predchozi_mesic.subscription.sum
        return Math.round(((suma_vsech_predplatnych - predchozi_suma_vsech_predplatnych) * 100/ suma_vsech_predplatnych))
      },
      podil_obnovenych_v_predplatnem(m){
        let suma_obnovenych = m.renewal.sum
        let celkem = m.renewal.sum + m.subscription.sum
        return Math.round(suma_obnovenych * 100 / celkem)
      },
      trend_obnovenych_v_predplatnem(m){
        // aktualni mesic neukazovat - nekompletni mesic nesmyslne udaje
        if (m.date == moment().format('YYYY-MM-DD')) {
          return "🤔"
        }

        let predchozi_mesic = this.stats.find((s) => s.date == moment(m.date).subtract(1, 'months').format('YYYY-MM-DD'))
        if (predchozi_mesic == undefined) {
          return 0
        }
        let suma_obnovenych = m.renewal.sum
        let predchozi_suma_obnovenych = predchozi_mesic.renewal.sum
        return Math.round(((suma_obnovenych - predchozi_suma_obnovenych) * 100/ suma_obnovenych))

      },
      podil_novych_predplatnych(m){
        let suma_novych = m.subscription.sum
        let celkem = m.renewal.sum + m.subscription.sum
        return Math.round(suma_novych * 100 / celkem)
      },
      trend_novych_predplatnych(m){
        // aktualni mesic neukazovat - nekompletni mesic nesmyslne udaje
        if (m.date == moment().format('YYYY-MM-DD')) {
          return "🤔"
        }

        let predchozi_mesic = this.stats.find((s) => s.date == moment(m.date).subtract(1, 'months').format('YYYY-MM-DD'))
        if (predchozi_mesic == undefined) {
          return 0
        }
        let suma_novych = m.subscription.sum
        let predchozi_suma_novych = predchozi_mesic.subscription.sum
        return Math.round(((suma_novych - predchozi_suma_novych) * 100/ suma_novych))+ "%"
      },
      podil_onetime(m){
        let suma_onetime = m.one_time_order.sum
        let celkem = m.renewal.sum + m.subscription.sum + m.one_time_order.sum
        return Math.round(suma_onetime * 100 / celkem)
      },
      trend_onetime(m){
        // aktualni mesic neukazovat - nekompletni mesic nesmyslne udaje
        if (m.date == moment().format('YYYY-MM-DD')) {
          return "🤔"
        }

        let predchozi_mesic = this.stats.find((s) => s.date == moment(m.date).subtract(1, 'months').format('YYYY-MM-DD'))
        if (predchozi_mesic == undefined) {
          return 0
        }
        let suma_onetime = m.one_time_order.sum
        let predchozi_suma_onetime = predchozi_mesic.one_time_order.sum
        return Math.round(((suma_onetime - predchozi_suma_onetime) * 100/ suma_onetime)) + "%"
      }




  }
}
</script>

<template>

  <table class="table table-bordered">
    <tr>
      <th></th>
      <th colspan="3"> Celkem	            </th>
      <th colspan="4"> Předplatné celkem  </th>
      <th colspan="4"> Obnovené předplatné</th>
      <th colspan="4"> Nové	předplatné	  </th>
      <th colspan="4"> jednorázové platby </th>

    </tr>
    <tr>
      <th>Období</th>
      <th>počet</th>
      <th>suma</th>
      <th>trend</th>



      <td>počet</td>
      <td>	suma</td>
      <td>	podíl</td>
      <td>	trend	</td>



      <td>počet</td>
      <td>	suma</td>
      <td>	podíl</td>
      <td>	trend	</td>

      <td>počet</td>
      <td>	suma</td>
      <td>	podíl</td>
      <td>	trend	</td>

      <td>počet</td>
      <td>	suma</td>
      <td>	podíl</td>
      <td>	trend	</td>
    </tr>
    <tr v-for="m in stats">
      <th>{{ mesic_z_datumu(m.date)}}</th>
      <td>{{ m.renewal.count+ m.subscription.count + m.one_time_order.count }}</td>
      <td>{{ Math.round(m.renewal.sum +m.subscription.sum +  m.one_time_order.sum) }}</td>
      <td>{{celkovy_trend(m)}}</td>


      <td>{{ m.renewal.count+ m.subscription.count }}</td>
      <td>{{ m.renewal.sum +m.subscription.sum}}</td>
      <td>{{podil_vsech_predplatnych(m)}}</td>
      <td>{{trend_sum_vsech_predplatnych(m)}}</td>


      <td>{{ m.renewal.count }}</td>
      <td>{{ m.renewal.sum }}</td>
      <td>{{podil_obnovenych_v_predplatnem(m)}}</td>
      <td>{{trend_obnovenych_v_predplatnem(m)}}</td>

      <td>{{ m.subscription.count }}</td>
      <td>{{ m.subscription.sum }}</td>
      <td>{{podil_novych_predplatnych(m)}}</td>
      <td>{{trend_novych_predplatnych(m)}}</td>

      <td>{{ m.one_time_order.count }}</td>
      <td>{{ m.one_time_order.sum }}</td>
      <td>{{podil_onetime(m)}}</td>
      <td>{{trend_onetime(m)}}</td>
    </tr>
  </table>

</template>

<style scoped>

</style>