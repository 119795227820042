<script setup>
import axios from "axios";
import _ from "lodash";
import OjMixin from "../oj-mixin";
import moment from "moment/moment";

import OjPagination from "../OjPagination.vue";
import SelectCourse from "../select-course.vue";
import WooLog2 from "../woo-log2.vue";

const STATUS_TO_COLOR_CLASS_MAP = {
  processing: 'text-info',
  created: 'text-info',
  pending: 'text-warning',
  failed: 'text-error',
  completed: 'text-success',
  cancelled: 'text-muted',
  refunded: 'text-info',
  expired: 'text-warning',
  active: 'text-success',
  on_hold: 'text-warning',
  pending_cancel: 'text-muted',
  deleted: 'text-info'
}


export default {
  name: "orders",
  components: {OjPagination, SelectCourse, WooLog2},
  mixins: [OjMixin],
  data() {
    return {
      woo_statuses_hash: {
        "processing": "Zpracovává se",
        "created": "Vytvořena", "pending": "Čeká na platbu", "failed": "Platba selhala",
        "completed": "Zaplacena", "cancelled": "Zrušena",
        "refunded": "Vrácená",
        "expired": "Vypršela",
        "active": "Aktivní", "on_hold": "Pozastavena",
        "pending_cancel": "Probíhá zrušení"
      },

      items: [],
      loading: false,

      search_string:'',


      selected_products: null,
      products: [],
      order_items: [],

      filter_open: false,
      koko:12312,
      eshops: [],
      q:{
        total_gteq:'',
        total_lteq:'',
        payment_paid_on_gteq:'',
        payment_paid_on_lteq:'',
        products_id_in:[],
        courses_id_in:[],
        payment_discount_code_cont:'',
        payment_payment_method_eq:''

      }
    }
  },
  mounted() {
    this.loadItems()
    this.loadProducts()
    axios.get('/api/woo/eshops.json' ).then(response =>{
      this.eshops = response.data.eshops
    })
  },
  watch:{
    per(){
      this.loadItems()
    },

    search_string(){
      this.search(this)
    },
    q: {
      handler: function (val, oldVal) {
        // this.search(this)
        console.log('change q', this.q)
      },
      deep: true
    }

  },
  methods: {

    search: _.debounce((vm) => {
      vm.loadItems()
    }, 550),

    csloseFilter() {
      this.filter_open = false
    },

    resetForm2(){
      this.search_string=''
      this.selected_products=[]
      this.q={
        total_lteq:'',
        payment_paid_on_gteq:'',
        payment_paid_on_lteq:'',
        products_id_in:[],
        courses_id_in:[],
        payment_discount_code_cont:'',
        payment_payment_method_eq:'',

      }

      this.$refs.q_courses_id_in.selected_course=[]

      this.loadItems()
      this.koko++
    },

    loadProducts() {

      axios.get('/api/products').then(response => {
        this.products = response.data
      })
    },

    onProductSelect(a) {
      console.log('SEL', a)


    },


    pageChanged(p) {
      this.page = p
      this.loadItems()
    },


    loadItems() {

      this.loading = true
      this.items = []


      let a = []
      for(let i in this.selected_products){
        a.push(this.selected_products[i].id)
      }
      this.q.products_id_in=a

      axios.post('/orders2.json', {

          q: this.q,
          search: this.search_string,
          per: this.per,
          page: this.page

      }).then(response => {
        this.loading = false
        this.items = response.data.items
        this.total = response.data.total

      }).catch(e => {
        this.$toasted.show(`se vyskytla chyba.`, {type: 'error'});
        this.loading = false
      })


    },


    order_status_to_color_class(status) {
      if (status == null) return ''
      return STATUS_TO_COLOR_CLASS_MAP[status]
    },


    couseSelect(kurzy){
      console.log('kurzy', kurzy)
      let a = []
      for(let  i in kurzy){
        a.push(kurzy[i].id)
      }
      this.q.courses_id_in = a

    }
  }
}
</script>

<template>
  <div class="card card-body index p-0">

    <div v-if="!filter_open" class="d-flex mt-2 ml-2">
      <div style="width:260px;">
        <button
           class="btn btn-outline-dark btn-sm" href="#orders_filter" role="button" @click.prevent="filter_open=true" style="display: inline-flex;">
         <span class="material-icons-outlined">
          search
          </span>
          Podrobné hledání
        </button>
      </div>

      <div class="input-group ml-2">
        <div class="input-group-prepend">
          <div class="input-group-text"><svg class="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg><!-- <i class="fas fa-search"></i> --></div>
        </div>
        <input placeholder="Hledat" class="form-control" type="text"  v-model="search_string" style="max-width: 300px"/>
      </div>
      <div class="ml-2" style="width:185px">
        <a href="/api_log/konverzky">Konverzky, raw data</a>
        <WooLog2/>
      </div>
    </div>
    <div class="well" v-if="filter_open" :key="koko">
      <div class="row">

        <div class="col-3 ">
          <label class=" " for="q_total_gteq">Total větší nebo rovno než</label>
          <input class="form-control" type="search" name="q[total_gteq]" id="q_total_gteq" v-model="q.total_gteq" />
        </div>


        <div class="col-3 ">
          <label>Total menší nebo rovno než</label>
          <input class="form-control" type="search" name="q[total_lteq]" id="q_total_lteq" v-model="q.total_lteq" />
        </div>


        <div class="col-3">
          <label>Paid at větší nebo rovno než</label>
          <input class="form-control" type="date" name="q[payment_paid_on_gteq]" id="q_payment_paid_on_gteq" v-model="q.payment_paid_on_gteq">
        </div>

        <div class="col-3 ">
          <label>Paid at menší nebo rovno než</label>
          <input class="form-control" type="date" name="q[payment_paid_on_lteq]" id="q_payment_paid_on_lteq" v-model="q.payment_paid_on_lteq">
        </div>


      </div>

      <div class="row my-3">

        <div class="col-md-4"><label>Eshop</label>
          <select  class="form-control " v-model="q.woo_eshop_id_eq">
            <option value="">Všechny</option>
            <option v-for="e in eshops" :value="e.id ? e.id : e.symbol">{{e.json_name}}</option>
          </select>
        </div>


        <div class="col-md-4">
          <!--%= it_form.select :product_id, options_for_select(Product.all.map { |pr| [pr.name, pr.id] }, it_form.object.product_id), { include_blank: true }, { class: 'form-control', data: { controller: 'selectize' } } %-->
          <label>Obsahuje produkty</label>
          <v-select label="name" name="q[products_id_in][]"
                    v-model="selected_products"
                    :filterable="true"
                    :multiple="true"
                    :options="products"
                    placeholder="Add Product"
                    @input="onProductSelect">

            <template #option="option">
              <div class="d-center">
                <img :src="option.small_image_url" v-if="option.small_image_url" class="icoproduct"> {{ option.name }}
              </div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">
                <img :src="option.small_image_url" v-if="option.small_image_url" class="icoproduct"> {{ option.name }}
              </div>
            </template>
          </v-select>
        </div>
        <div class="col-md-4">
          <label>Obsahuje kurz</label>
          <select-course @couseSelect="couseSelect"  :multiple="true" :course-id="q.courses_id_in"  name="q[courses_id_in][]" ref="q_courses_id_in"></select-course>
        </div>


      </div>

      <div class="row">

        <div class="col-4">
          <div class="form-group ">
            <label class="" for="q_discount_code_cont">Slevový kód obsahuje</label>

            <input class="form-control" type="search" name="q[payment_discount_code_cont]"
                   v-model="q.payment_discount_code_cont"
                   id="q_payment_discount_code_cont">

          </div>
        </div>


        <div class="col-sm-4">
          <label>Payment, Způsob platby </label>
          <select class="form-control" name="q[payment_payment_method_eq]"  v-model="q.payment_payment_method_eq" id="q_payment_payment_method_eq">
            <option value=""></option>
            <option value="1">Manual</option>
            <option value="2">Free</option>
            <option value="3">Card</option>
            <option value="4">Wire Transfer</option>
            <option value="5">Other</option>
            <option value="6">Gopay</option>
            <option value="7">Paypal</option>
            <option value="8">Stripe</option>
            <option value="9">Gopay Inline</option>
            <option value="10">Benefity</option>
            <option value="104">Transfer</option>
            <option value="110">Benefit</option>
          </select>
        </div>

        <div class="col-sm-4">
          <div class="form-group ">
            <label>Stav </label>
            <select class="form-control" name="q[status_eq]" v-model="q.status_eq" id="q_status_eq">
              <option value=""></option>
              <option value="0">Zpracovává se</option>
              <option value="1">Vytvořena</option>
              <option value="2">Čeká na platbu</option>
              <option value="3">Platba selhala</option>
              <option value="4">Zaplacena</option>
              <option value="5">Zrušena</option>
              <option value="6">Vrácená</option>
              <option value="7">Vypršela</option>
              <option value="8">Aktivní</option>
              <option value="9">Pozastavena</option>
              <option value="10">Probíhá zrušení</option>
            </select>
          </div>
        </div>


      </div>


      <div class="d-flex">
        <input value="Vyhledávání" class="btn btn-primary float-right"  data-disable-with="Vyhledávání" @click.prevent="loadItems"/>
        <button type="reset" @click="resetForm2" class="btn  btn-outline-info ml-1">Reset</button>

        <div class="ml-auto pt-2">
          <a class="c-close" @click.prevent="csloseFilter">
            <svg viewBox="0 0 24 24">
              <g class="stroke" fill="none" fill-rule="evenodd" stroke="#000000">
                <path d="M2 2l20 20M22 2L2 22"></path>
              </g>
            </svg>
          </a>
        </div>
      </div>
    </div>


    <div class="listing">



      <div class="ml-3 d-flex">
        <div class="mt-3 mr-2">na stránce</div>
        <select v-model="per" class="form-control w-auto mt-2 mr-2">
          <option>10</option>
          <option>20</option>
          <option>30</option>
          <option>40</option>
          <option>50</option>
        </select>


        <OjPagination
            :totalItems="total"
            :currentPage="page"
            :perPage="per"
            @pageChanged="pageChanged" class="mt-2"/>

        <div  class="mt-3 ml-2">
          z celkových {{total}}
        </div>

      </div>

      <div class="text-center" v-if="loading">
        <img src="/img/1495.gif"/>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th>
              Číslo objednávky
            </th>
            <th>
              <a class="sort" data-sort="email:asc"
                 href="/orders?list%5Bpage%5D=2&amp;list%5Bsort%5D=email%3Aasc&amp;listing=list">E-mail</a>
            </th>
            <th>
              Order date
            </th>
            <th>
              Eshop
            </th>
            <th>
              <a class="sort" data-sort="discount_code:asc"
                 href="/orders?list%5Bpage%5D=2&amp;list%5Bsort%5D=discount_code%3Aasc&amp;listing=list">Slevový kód</a>
            </th>
            <th>
              Type name
            </th>
            <th>
              <a class="sort" data-sort="status:asc"
                 href="/orders?list%5Bpage%5D=2&amp;list%5Bsort%5D=status%3Aasc&amp;listing=list">Stav</a>
            </th>
            <th>
              Název produktu
            </th>
            <th class="actions">
              Akce
            </th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="order in items">
            <td>
              <a :href="`/orders/${order.id}`">{{ order.order_number }}</a>
            </td>
            <td>
              <a :href="`/students/${order.student_id}`">{{ order.student.email }}</a>
              <span style="float: right">{{ order.payment.billing_country || order.payment.country }}</span>
              <span class="small"><br> {{ order.payment.vat_number }}</span>
            </td>
            <td>
          <span class="small">
            {{ order.order_date | dateTime2 }}
            <div v-if="order.payment_date"> {{ order.payment_date | dateTime2 }} </div>
          </span>
            </td>

            <td>
            <span v-if="order.has_onlajnovka">
              <span class="small">{{ order.eshop }}</span>
              <br>ONLAJNOVKA
            </span>
              <span v-else>            {{ order.eshop }}</span>
            </td>
            <td>{{ order.payment.discount_code }}</td>

            <td>{{ order.type_name }}</td>

            <td>
              <div :class="order_status_to_color_class(order.status)">
                {{ woo_statuses_hash[order.status] }}
              </div>
              <span class="small">{{ order.payment.total }}   {{ order.currency_code }}

            </span>
            </td>


            <td>
              <span class="small" v-html="order.product_names.join(`<br />`)"></span>
            </td>


            <td class="actions">
              &nbsp;&nbsp;
              <a data-confirm="Do you really want to delete WooOrder?"
                 class="text-danger" rel="nofollow"
                 data-method="delete"
                 :href="`/orders/${order.id}`">
                <svg class="svg-inline--fa fa-times fa-w-11" aria-hidden="true"
                     focusable="false"
                     data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 352 512"
                     data-fa-i2svg="">
                  <path fill="currentColor"
                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                </svg>
                <!-- <i class="fas fa-times"></i> --></a>

            </td>
          </tr>


          </tbody>

        </table>
      </div>
    </div>
  </div>

</template>

<style>
.well .v-select{
  background: white;
}
</style>