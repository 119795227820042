<template>
  <div class="level-selector">
    <a href="#" :class="`badge large badge-${l==1 ? 'primary':'light'}`" @click.prevent="ch(1)">Level 1</a>
    <a href="#" :class="`badge badge-${l==2 ? 'primary':'light'}`" @click.prevent="ch(2)">Level 2</a>
    <a href="#" :class="`badge badge-${l==3 ? 'primary':'light'}`" @click.prevent="ch(3)">Level 3</a>
    <a href="#" :class="`badge badge-${l==4 ? 'primary':'light'}`" @click.prevent="ch(4)">Level 3-4</a>
  </div>
</template>

<script>
export default {
  props: ['level', 'disabled'],
  name: "level-switch",
  watch:{
    level(v){
      this.l=v
    }
  },
  data(){
    return({
      l:1
    })
  },
  methods:{
    ch(lev){
      if (this.disabled) return
      this.l=lev
      this.$emit('update', lev)
    }

  }


}
</script>

<style scoped>
.level-selector{
  font-size:20px;
}

</style>