// listpress support
import "./listpress"

import "./date_time_picker"
/*
$(function() {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip(/*{ "delay": { "show": 1000, "hide": 5000 } }* / )
  })
})
*/