import { render, staticRenderFns } from "./top-selling-table.vue?vue&type=template&id=99250aec&scoped=true&"
import script from "./top-selling-table.vue?vue&type=script&lang=js&"
export * from "./top-selling-table.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99250aec",
  null
  
)

export default component.exports