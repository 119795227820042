<script>
import axios from "axios";

export default {
  name: 'FilterProductId',
  props: ['value', 'filter', 'disable_label'],
  data(){
    return {
      products: [],
      selected_products: []
    }
  },
  mounted() {
    this.loadProducts()
  },
  watch:{
    value(){
      console.log('watch', this.value, 'products', this.products.length)
      this.selected_products = []
      for(let i = 0; i < this.products.length; i++){
        let pid = `${this.products[i].id}`

        if (this.value.indexOf(pid) == -1){
          continue
        }
        this.selected_products.push(this.products[i])
      }
    }
  },
  methods:{
    loadProducts(){
      axios.get('/api/products.json').then((response) => {
        this.products = response.data


        console.log('na init', this.value)
        for(let i = 0; i < this.products.length; i++){
          let pid = this.products[i].id



          if (this.value.indexOf(pid) == -1){
            continue
          }
          this.selected_products.push(this.products[i])
        }
      })
    },
    onProductSelect(){
      const ids = this.selected_products.map((product) => product.id)
      this.$emit('input', this.filter,  ids)
    }
  }
}

</script>

<template>
<div>
  <label v-if="!disable_label">Obsahuje produkty</label>
          <v-select label="name" name="q[products_id_in][]"
                    v-model="selected_products"
                    :filterable="true"
                    :multiple="true"
                    :options="products"
                    placeholder="Add Product"
                    @input="onProductSelect">

            <template #option="option">
              <div class="d-center">
                <img :src="option.small_image_url" v-if="option.small_image_url" class="icoproduct"> {{ option.name }}
              </div>
            </template>
            <template #selected-option="option">
              <div class="selected d-center">
                <img :src="option.small_image_url" v-if="option.small_image_url" class="icoproduct"> {{ option.name }}
              </div>
            </template>
          </v-select>

</div>
</template>

<style scoped>

</style>