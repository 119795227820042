<script >
import axios from "axios";
import OjPagination from "../OjPagination.vue";
import FilterDateRange from './filters/date-range.vue'
import FilterProductId from './filters/product-id.vue'
import FilterWooEshopId from './filters/woo-eshop-id.vue'
import FilterCourseId from './filters/course-id.vue'
import moment from "moment";
import _ from "lodash";

const order_typ_enum={
  one_time_order: "Jednorázová objednávka",
  order_and_subscription: 'Objednávka a předplatné' ,
  renewal: 'Prodloužení předplatného',
  refunded_order: 'Vratka'
}

const order_statuses_enum={
  pending: 'Čeká na platbu',
  processing: 'Zpracovává se',
  on_hold: 'Čeká na platbu',
  completed: 'Dokončeno',
  cancelled: 'Zrušeno',
  refunded: 'Vráceno',
  failed: 'Chyba'
}


export default {
  name: 'AppTable',
  props: ['app', 'fields', 'datatransformer', 'pers', 'default_per', 'enable_search', 'filters', 'actions'],
  components: {OjPagination, FilterDateRange, FilterProductId, FilterWooEshopId, FilterCourseId},

  data() {
    return {
      items: [],
      search_string: '',
      filter_open: false,
      total: 0,
      page: 1,
      per: this.default_per || 50,
      ppers: [10, 20, 30, 50, 80],
      filter_params: {},
      kii: 4000,
      loading: false,
      koko:1
    }
  },
  mounted() {
    if (this.pers) this.ppers = this.pers
    this.setFilterDefaultValues()
    this.loadItems()



  },
  watch: {
    per() {
      this.page = 1
      this.loadItems()
      this.kii++
    },

    search_string() {
      this.search(this)
    }
  },

  computed: {
    filtered_cols_count() {
      let c = 0
      for (let i in this.fields) {
        if (this.fields[i].filter) c++
      }
      return c
    }

  },

  methods: {
    setFilterDefaultValues(){
      for (let i in this.filters){
        if (this.filters[i].default!=undefined){
          this.filter_params[this.filters[i].codename] = this.filters[i].default
        }
      }
    },
    normalized_filter_params() {
      const dest = {}
      for (let i in this.filter_params) {
        // kdyz odpovida foo[bar] tak udela hash foo{bar: value}
        const m = i.match(/(.+)\[(.+)\]/)
        if (m) {
          if (!dest[m[1]]) dest[m[1]] = {}
          dest[m[1]][m[2]] = this.filter_params[i]
        } else {
          dest[i] = this.filter_params[i]

        }
      }

      return dest
    },
    handleClearFilter() {
      this.filter_params = {}
      this.search_string = ''
      this.page = 1
      this.loadItems()
    },
    search: _.debounce((vm) => {
      vm.page = 1
      vm.loadItems()
    }, 550),
    pageChanged(p) {
      this.page = p
      this.loadItems()
    },
    loadItems() {
      this.loading = true
      let params = {
        per: this.per,
        page: this.page,
        search: this.search_string,
        filter: this.normalized_filter_params()
      }
      axios.get(this.app.data_url ? this.app.data_url : `/formgenerator/app/${this.app.codename}/index`, {params: params}).then(response => {
        if (typeof this.datatransformer == 'function')
          this.items = this.datatransformer(response.data.items)
        else
          this.items = response.data.items
        this.total = response.data.total
        this.loading = false
        this.$emit('itemsLoaded', response.data.items)
      })
    },

    itemClick(item) {
      this.$emit('itemClick', item)
    },

    date(value) {
      value = moment(value).format('DD.MM.YYYY')
      return value
    },

    dateTime(value) {
      value = moment(value).format('DD.MM.YYYY H:mm')
      return value
    },

    filterItemChange(field) {
     // console.log('filterItemChange', field)
      this.page = 1
      this.loadItems()
    },

    filterComponentCahnge(field, value) {
   //   console.log('filterComponentCahnge', field.codename, value)

      this.filter_params[field.codename] = value
      this.page = 1
      this.loadItems()
    },

    order_status_to_color_class(v) {
      if (!v) return ''
      const STATUS_TO_COLOR_CLASS_MAP = {
        processing: 'text-info',
        created: 'text-info',
        pending: 'text-warning',
        failed: 'text-error',
        completed: 'text-success',
        cancelled: 'text-muted',
        refunded: 'text-info',
        expired: 'text-warning',
        active: 'text-success',
        on_hold: 'text-warning',
        pending_cancel: 'text-muted',
        deleted: 'text-info'
      }
      return STATUS_TO_COLOR_CLASS_MAP[v]
    },

    getCategoryConfig(c){
      let a = this.fields.filter(f => f.type == 'category' && f.codename == c.codename)
      return a[0]
    },

    getCategorySelectedItem(v, c) {
      //const v = item[c.codename]

      let a = this.getCategoryConfig(c)

      let cfg = {}
      for (let i in a.items) {
        console.log(a[i], v)
        if (a.items[i].value == v) cfg = a.items[i]
      }
      return cfg
    },

    preformat(item, c) {
      const v = item[c.codename]

      if (v==undefined || v==null) return ''

      if (c.display_filter == 'dateTime') return this.dateTime(v)
      if (c.display_filter == 'order_event_name') return order_typ_enum[v]
      if (c.display_filter == 'order_status_name') return `<span class="${this.order_status_to_color_class(v)}">${order_statuses_enum[v]}</span>`
      if (c.display_filter == 'strong') return `<strong>${v ? v : ''}</strong>`
      if (c.display_filter == 'student') return `<a href="/students/${v.id}" target="_blank">${v.email}</a>`
      if (c.display_filter == 'boolean') return v ? 'ano' : 'ne'

      if (c.type == 'category'){
        if (v.indexOf(',')>=0){
          const arr = v.split(',')
          let out = []
          for (let i in arr){
            const cfg = this.getCategorySelectedItem(arr[i], c)
            out.push(`<span class="color-${cfg.color}">${cfg.t}</span>`)
          }
          return out.join(', ')
        }
        const cfg = this.getCategorySelectedItem(v, c)
        return `<span class="color-${cfg.color}">${cfg.t}</span>`
      }
      if (c.type == 'user'){
        const users = item[c.codename+'_user']
        if (users && users.length){
          if (users.length == 1){
            return `${users[0].first_name}&nbsp;${users[0].last_name}`
          }
          if (users.length > 5){
            return "<i class='slaba-poznamka'>více než 5 uživatelů</i>"
          }
          return users.map(u => `<span class="badge-user-item">${u.first_name}&nbsp;${u.last_name}</span>`).join('  ')
        }
      }

      //  # "label":"rel333","type":"relation","codename":"rel333","rel_app":"napady"     if f['type'] == 'relation'

      if (c.type == 'relation'){
        const rel = item[c.codename+'_obj']
        if (rel){
          return `<a href="/formgenerator/app/${c.rel_app}/#edit/${rel.id}" >${rel.obj_alt}</a>`
        }
      }

      if (c.display_filter == 'link'){
        const url = c.link_url.replaceAll(':id', item.id)
        return `<a href="${url}" target="${c.target==undefined ? '' : c.target}">${v}</a>`
      }

      return v
    },
    deletable() {

      if (!this.app.actions) return false
      return this.app.actions.indexOf('delete') >= 0
    },
    handleDelete(item) {
      if (!confirm("Opravdu samzat ?")) return
      if (this.app.destroy_url) {
        const u = this.app.destroy_url.replaceAll(":id", item.id)
        axios.delete(u).then(resp => {
          this.loadItems()
        }).catch(err => {
          this.$toasted.show(`Chyba: ${err.response.data.errors.join('<br>')}`, {type: 'error'});
        })
      }
    },

    dateRangeChange(f, d_od, d_do) {
      console.log('DRCH', d_od, d_do)
      this.filter_params[`${f.codename}_date_to`] = d_do
      this.filter_params[`${f.codename}_date_from`] = d_od
      this.search(this)
    },

    normalizedOptions(src) {


      const dest = []
      if (typeof src == 'object' && src.length>0 && src[0].value != undefined)  {

        return src
      }
      if (src instanceof Array) {
        for (let i in src) {
          if (typeof src[i] == 'string') {
            dest.push({value: src[i], label: src[i]})
          }
        }
      } else {
        if (typeof src == 'object') {
          for (let i in src) {
            dest.push({value: i, label: src[i]})
          }
        }
      }
      ``
      return dest
    }

  }
}
</script>

<template>
  <div>


    <div v-if="!filter_open && app.filter_default_hidden" class="d-flex mt-2 ml-2">
      <div style="width:260px;" v-if="filters.length>0">
        <button
            class="btn btn-outline-dark btn-sm" href="#orders_filter" role="button" @click.prevent="filter_open=true"
            style="display: inline-flex;">
         <span class="material-icons-outlined">
          search
          </span>
          Podrobné hledání
        </button>
      </div>

      <div class="input-group ml-2">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <svg class="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas"
                 data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                 data-fa-i2svg="">
              <path fill="currentColor"
                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
            </svg><!-- <i class="fas fa-search"></i> --></div>
        </div>
        <input placeholder="Hledat" class="form-control" type="text" v-model="search_string" style="max-width: 300px"/>
      </div>
    </div>


    <div class="well" v-if="filters && (filter_open || !app.filter_default_hidden)" :key="koko">
      <div class="filtr row" v-if="filters && filters.length>0">
        <div class="col-md-4" v-for="f in filters">
          <FilterDateRange :filter="f" v-if="f.type=='date-range'" @change="dateRangeChange"/>

          <div class="form-group" v-if="f.type=='select'">
            <label>{{ f.label }}</label>
            <select class="form-control" v-model="filter_params[f.codename]" @change="filterItemChange(f)">
              <option :value="oo.value" v-for="oo in normalizedOptions(f.options)">{{ oo.label }}</option>
            </select>
          </div>

          <FilterProductId :filter="f" v-if="f.type=='product_id'" @input="filterComponentCahnge"/>
          <FilterWooEshopId :filter="f" v-if="f.type=='woo_eshop_id'" @input="filterComponentCahnge"/>
          <FilterCourseId :filter="f" v-if="f.type=='course_id'" @input="filterComponentCahnge"/>

          <div class="form-group" v-if="f.type=='text' || f.type=='number'">
            <label>{{ f.label }}</label>
            <input :type="f.type" class="form-control" v-model="filter_params[f.codename]"
                   @change="filterItemChange(f)"/>
          </div>

          <div class="form-group" v-if="f.type=='search'">
            <label>&nbsp;</label>
            <div class="input-group ml-2">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" role="img"
                       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""
                       class="svg-inline--fa fa-search fa-w-16">
                    <path fill="currentColor"
                          d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                  </svg>
                </div>
              </div>
              <input placeholder="Hledat" type="text" class="form-control" v-model="search_string"/>
            </div>
          </div>

        </div>

      </div>

      <div class="ml-auto" v-if="app.filter_default_hidden">
        <a class="c-close" @click.prevent="filter_open = false">
          <svg viewBox="0 0 24 24">
            <g class="stroke" fill="none" fill-rule="evenodd" stroke="#000000">
              <path d="M2 2l20 20M22 2L2 22"></path>
            </g>
          </svg>
        </a>
      </div>


    </div><!-- well -->

    <div class="row" v-if="filtered_cols_count">
      <div class="col-md-2 text-right ml-auto">
        <button class="btn2 btn-outline-dark" @click.prevent="handleClearFilter()">Vyčistit filtr</button>
      </div>
    </div>

    <div class="ml-3 d-flex" v-if="total>10" :key="`duuu${kii}`">
      <div class="mt-3 mr-2 ml-auto">stránkovani po</div>
      <select v-model="per" class="form-control w-auto mt-2 mr-2">
        <option v-for="p in ppers" :value="p">{{ p }}</option>
      </select>


      <OjPagination
          :totalItems="total"
          :currentPage="page"
          :perPage="per"
          @pageChanged="pageChanged" class="mt-2"/>

      <div class="mt-3 ml-2 mr-auto">
        z celkových {{ total }}
      </div>
    </div>

    <div v-if="items.length==0 && !loading" class="nic text-center">
      nic nebylo nalezeno
    </div>
    <div class="text-center" v-if="loading">
      <img src="/img/1495.gif"/>
    </div>
    <table class="table table-striped custom-table" v-else>
      <thead>
      <tr>
        <th v-if="!app.hidden_id">&nbsp;</th>
        <th v-for="c in fields">{{ c.label }}</th>
        <th v-if="deletable()">...</th>
        <th v-for="act in actions">{{ act.header || '' }}</th>

      </tr>
      </thead>
      <tbody>
      <tr v-for="item in items" @click="itemClick(item)">
        <td v-if="!app.hidden_id">{{ item.id }}</td>
        <td v-for="c in fields" :class="`cell-${c.codename}`" v-html="preformat(item, c )"></td>

        <td v-if="deletable()">
          <a href="#" @click.prevent="handleDelete(item)" title="smazat"><span class="material-icons-outlined mazani">delete</span></a>
        </td>

        <td v-for="act in actions">
          <button @click.prevent="act.method(item)" class="btn btn-small">{{ act.label }}</button>
        </td>
      </tr>
      </tbody>
    </table>


    <div class="ml-3 d-flex" v-if="total>10" :key="`druhejpaginator${kii}`">
      <div class="mt-3 mr-2 ml-auto">stránkovani po</div>
      <select v-model="per" class="form-control w-auto mt-2 mr-2">
        <option v-for="p in ppers" :value="p">{{ p }}</option>
      </select>


      <OjPagination
          :totalItems="total"
          :currentPage="page"
          :perPage="per"
          @pageChanged="pageChanged" class="mt-2"/>

      <div class="mt-3 ml-2 mr-auto">
        z celkových {{ total }}
      </div>
    </div>

  </div>
</template>

<style scoped>
.mazani {
  color: #cc0000;
}


</style>