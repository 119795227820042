import { render, staticRenderFns } from "./app-product-skumapping.vue?vue&type=template&id=0c65a126&scoped=true&"
import script from "./app-product-skumapping.vue?vue&type=script&lang=js&"
export * from "./app-product-skumapping.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c65a126",
  null
  
)

export default component.exports