import moment from "moment/moment";
const monthNames = [
    'leden', 'únor', 'březen', 'duben', 'květen', 'červen',
    'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'
];

const studentStatsMixin = {
    data() {
        return {
            sources:[
                {name: 'web_oj', label: 'OJ CZ'},
                {name: 'web_el_cz', label: 'EL CZ'},
                {name: 'web_el_sk', label: 'EL SK'},
                {name: 'web_el_pl', label: 'EL ES'},
                {name: 'web_el_es', label: 'EL PL'},
                {name: 'rozrazovacitest', label: 'Rozř.'},
                {name: 'ios_el', label: 'iP EL'},
                {name: 'ios_oj', label: 'iP OJ'},
                {name: 'android_el', label: 'An EL'},
                {name: 'android_oj', label: 'An OJ'},
                {name: 'web_kk', label: 'KKlb'},
                {name: 'google', label: 'Ggle'},
                {name: 'facebook', label: 'Fcbk'},
            ]
        };
    },
    methods:{
        formatCurrency(amount) {
            return amount.toLocaleString('cs-CZ', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
        },
        getShortenedMonths() {
            // aby senevypisoval posledni mesic, z nej se maj jen pocitat statistiky trendu
            return this.stats.months.slice(0, -1);
        },

        mesic_z_datumu(date_string) {
            const monthIndex = moment(date_string).month();
            return monthNames[monthIndex];
        },

        findValue(data, kind, level){
            let students_count = 0;
            for (let i = 0; i < data.length; i++) {
                if (data[i].source_kind === kind && data[i].level === level) {
                    return data[i].student_count;
                    break;
                }
            }
            return 0
        },
        levelSum(students, kind){
            let sum = 0;
            for (let i = 0; i < students.length; i++) {
                if (students[i].source_kind === kind) {
                    sum += students[i].student_count;
                }
            }
            return sum;
        },
        allKindSum(students, level){
            let allowed_sources_names = this.sources.map((source) => source.name);
            let sum = 0;
            for (let i = 0; i < students.length; i++) {
                if (students[i].level === level && allowed_sources_names.includes(students[i].source_kind)) {

                    sum += students[i].student_count;
                }
            }
            return sum;
        },
        allKindAllLevelSum(students){
            let allowed_sources_names = this.sources.map((source) => source.name);
            let sum = 0;
            for (let i = 0; i < students.length; i++) {
                if (allowed_sources_names.includes(students[i].source_kind)) {
                    sum += students[i].student_count;
                }
            }
            return sum;
        }
    }
}

export default studentStatsMixin