import { render, staticRenderFns } from "./RemoveField.vue?vue&type=template&id=d45b6d52&scoped=true&"
import script from "./RemoveField.vue?vue&type=script&lang=js&"
export * from "./RemoveField.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d45b6d52",
  null
  
)

export default component.exports