<script>

import axios from "axios";

export default {
  name: "f-users",
  props: ['options', 'item'],
  data(){
    return {
      selected_user:[],
      searchoptions:[]


    }
  },
  mounted() {
    this.loadUsers()
  },
  watch:{
    selected_user(){
      let arr = []

      console.log('selected_user',  this.selected_user)
      if (!this.selected_user.length){
        arr.push(this.selected_user.id)
      }else {

        for (let i in this.selected_user) {
          arr.push(this.selected_user[i].id)
        }
      }

      this.item[this.options.codename] = arr.join(',')
      this.$emit('change', arr.join(','))
    },
    item(){
      this.loadUsers()
    }
  },
  methods:{
    onSearch(search, loading) {
      if(search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },

    search: _.debounce((loading, search, vm) => {
      fetch(
          `/formgenerator/users?search=${encodeURI(search)}`
      ).then(res => {
        res.json().then(json => (vm.searchoptions = json.users));
        loading(false);
      });
    }, 350),


    loadUsers(){
      let ids = this.item[this.options.codename]
      if (!ids){
        return
      }
      axios.get(`/formgenerator/users?ids=${encodeURI(ids)}`).then(response=>{
        this.selected_user = response.data.users
      })
    }
  }
}

</script>

<template>

<div class="form-group">
  <label>{{options.label}}  </label>
    <v-select v-model="selected_user" @search="onSearch" :filterable="false" :options="searchoptions" :multiple="options.multiple">

        <template #option="option">
          <div>
            {{ option.first_name }} {{option.last_name}}

          </div>
        </template>
        <template #selected-option="option">
          <div class="selected">
            {{ option.first_name }} {{option.last_name}}
          </div>
        </template>
      </v-select>


  </div>

</template>

<style scoped>

</style>