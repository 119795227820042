<script>
import studentStatsMixin from './student-stats-mixin'

import moment from "moment/moment";
export default {
  name: "subscription-1m" ,
  props: ['stats'],
  mixins: [studentStatsMixin],
  /*
  * {
	"subs": {
		"id": 7,
		"month": "2024-09-01",
		"stopped1_count": 33,
		* :stop6_count,
		* :stop12_count,
		"active1_count": 266,
		"active6_count": 131,
		"active12_count": 104,
		"started1_count": 32,
		"started6_count": 29,
		"started12_count": 14,
		"sum1_totals": "1608.0",
		"sum6_totals": "39974.1",
		"sum12_totals": "34540.8",
		"currency_id": 1,
		"renew6_count": 10,
		"renew12_count": 8,
		"renew6_totals": "0.0",
		"renew12_totals": "0.0",
		"average_lifetime": "2.65",
		"created_at": "2024-09-06T04:01:34.502+02:00",
		"updated_at": "2024-09-06T15:35:02.038+02:00"
	}
}
  * */


  methods:{
    filteredStats() {
      return this.stats.slice(0, -1);
    },
    // trendy jen šipkou kromě peněz, tam bych nechal číslo
    active_trend(s){
      let soucasne = s.subs.active1_count
      let m = s.month
      let minuy_m = moment(m).subtract(1, 'month').format('YYYY-MM-DD')
      let minuly_mesic = this.stats.find(s => s.month === minuy_m)

      if (minuly_mesic) {
        let minuly = minuly_mesic.subs.active1_count
        if (minuly == 0) {
          return "n/a"
        }
        let trend = (soucasne - minuly) / minuly

        if (trend > 0) {
          return " ↑"
        } else {
          return " ↓"
        }

      } else {
        return "n/a"
      }
    },
    started_trend(s){
      let soucasne = s.subs.started1_count
      let m = s.month
      let minuy_m = moment(m).subtract(1, 'month').format('YYYY-MM-DD')
      let minuly_mesic = this.stats.find(s => s.month === minuy_m)

      if (minuly_mesic) {
        let minuly = minuly_mesic.subs.started1_count
        if (minuly == 0) {
          return "n/a"
        }
        let trend = (soucasne - minuly) / minuly

        if (trend > 0) {
          return " ↑"
        } else {
          return " ↓"
        }

      } else {
        return "n/a"
      }
    },
    stopped_trend(s){
      let soucasne = s.subs.stopped1_count
      let m = s.month
      let minuy_m = moment(m).subtract(1, 'month').format('YYYY-MM-DD')
      let minuly_mesic = this.stats.find(s => s.month === minuy_m)

      if (minuly_mesic) {
        let minuly = minuly_mesic.subs.stopped1_count
        if (minuly == 0) {
          return "n/a"
        }
        let trend = (soucasne - minuly) / minuly

        if (trend > 0) {
          return " ↑"
        } else {
          return " ↓"
        }

      } else {
        return "n/a"
      }
    },
    zivotnost_trend(s) {
      let soucasne = s.subs.average_lifetime
      let m = s.month
      let minuy_m = moment(m).subtract(1, 'month').format('YYYY-MM-DD')
      let minuly_mesic = this.stats.find(s => s.month === minuy_m)

      if (minuly_mesic) {
        let minuly = minuly_mesic.subs.average_lifetime
        if (minuly == 0) {
          return "n/a"
        }
        let trend = (soucasne - minuly) / minuly

        if (trend > 0) {
          return " ↑"
        } else {
          return " ↓"
        }

      } else {
        return "n/a"
      }
    },

    trend_sum_totals(s) {
      let soucasne = s.subs.sum1_totals
      let m = s.month
      let minuy_m = moment(m).subtract(1, 'month').format('YYYY-MM-DD')
      let minuly_mesic = this.stats.find(s => s.month === minuy_m)

      if (minuly_mesic) {
        let minuly = minuly_mesic.subs.sum1_totals
        if (minuly == 0) {
          return "n/a"
        }
        let trend = (soucasne - minuly) / minuly

        if (trend > 0) {
          return " ↑"
        } else {
          return " ↓"
        }

      } else {
        return "n/a"
      }
    }
  }
}
</script>

<template>
  <div class="card card-body">

    <table class="table table-bordered">

    <tr>
      <th></th>
      <th>aktivní</th>
      <th>trend</th>
      <th>nová</th>
      <th>trend</th>
      <th>zrušená</th>
      <th>trend</th>
      <th>životnost</th>
      <th>trend</th>
      <th>∑ plateb</th>
      <th>trend</th>
    </tr>
    <tr v-for="s in filteredStats()">
      <td>{{  mesic_z_datumu(s.month) }}</td>
      <td>{{s.subs.active1_count}} </td>
      <td>{{active_trend(s)}}</td>

      <td>{{s.subs.started1_count}}</td>
      <td>{{started_trend(s)}}</td>

      <td>{{s.subs.stopped1_count}}</td>
      <td>{{stopped_trend(s)}}</td>

      <td>{{s.subs.average_lifetime }}</td>
      <td>{{zivotnost_trend(s)}}</td>

      <td>{{formatCurrency(Math.round(s.subs.sum1_totals))}}   </td>
      <td>{{trend_sum_totals(s)}}</td>
    </tr>
  </table>
  </div>
</template>

<style scoped>

</style>