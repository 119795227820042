<script>

import studentStatsMixin from './student-stats-mixin'

import moment from "moment/moment";

export default {
  props: ['stats'],

  /*
  * stats = {
	"0": {
		"month": "2024-09-01",
		"subs": {
			"id": 7,
			"month": "2024-09-01",
			"stopped1_count": 28,
			"active1_count": 270,
			"active6_count": 109,
			"active12_count": 94,
			"started1_count": 19,
			"started6_count": 7,
			"started12_count": 4,
			"sum1_totals": "1004.0",
			"sum6_totals": "11341.6",
			"sum12_totals": "9022.8",
			"currency_id": 1,
			"renew6_count": 10,
			"renew12_count": 8,
			"renew6_totals": "0.0",
			"renew12_totals": "0.0",
			"average_lifetime": "2.62",
		}
	} ....
}
  *
  * */


  mixins: [studentStatsMixin],
  name: "subscription-stats",
  methods: {
    trend1m(st) {
      // trend z sum1_totals v porovnani s predchozim mesicem
      let soucasne = st.subs.sum1_totals
      let m = st.month
      let minuy_m = moment(m).subtract(1, 'month').format('YYYY-MM-DD')
      let minuly_mesic = this.stats.find(s => s.month === minuy_m)

      if (minuly_mesic) {
        let minuly = minuly_mesic.subs.sum1_totals
        if (minuly == 0) {
          return "n/a"
        }
        let trend = (soucasne - minuly) / minuly
        return Math.round(trend*100)/100 + "%"
      } else {
        return "n/a"
      }

    },
    trendZrusenych1m(s){
      let soucasne = s.subs.stopped1_count
      let m = s.month
      let minuy_m = moment(m).subtract(1, 'month').format('YYYY-MM-DD')
      let minuly_mesic = this.stats.find(s => s.month === minuy_m)

      if (minuly_mesic) {
        let minuly = minuly_mesic.subs.stopped1_count
        if (minuly == 0) {
          return "n/a"
        }
        let trend = (soucasne - minuly) / minuly
        return Math.round(trend*100)/100 + "%"
      } else {
        return "n/a"
      }
    },
    trendAverageLifetime(s){
      let soucasne = s.subs.average_lifetime
      let m = s.month
      let minuy_m = moment(m).subtract(1, 'month').format('YYYY-MM-DD')
      let minuly_mesic = this.stats.find(s => s.month === minuy_m)

      if (minuly_mesic) {
        let minuly = minuly_mesic.subs.average_lifetime
        if (minuly == 0) {
          return "n/a"
        }
        let trend = (soucasne - minuly) / minuly
        return Math.round(trend*100)/100 + "%"
      } else {
        return "n/a"
      }
    },

    trendSum6Totals(s){
      let soucasne = s.subs.sum6_totals
      let m = s.month
      let minuy_m = moment(m).subtract(1, 'month').format('YYYY-MM-DD')
      let minuly_mesic = this.stats.find(s => s.month === minuy_m)

      if (minuly_mesic) {
        let minuly = minuly_mesic.subs.sum6_totals
        if (minuly == 0) {
          return "n/a"
        }
        let trend = (soucasne - minuly) / minuly
        return Math.round(trend*100)/100 + "%"
      } else {
        return "n/a"
      }
    },

    trendSum12Totals(s){
      let soucasne = s.subs.sum12_totals
      let m = s.month
      let minuy_m = moment(m).subtract(1, 'month').format('YYYY-MM-DD')
      let minuly_mesic = this.stats.find(s => s.month === minuy_m)

      if (minuly_mesic) {
        let minuly = minuly_mesic.subs.sum12_totals
        let trend = (soucasne - minuly) / minuly
        return Math.round(trend*100)/100 + "%"
      } else {
        return "n/a"
      }
    },
    procentoZrusenych(s){
      let soucasne = s.subs.stopped1_count
      let celkem = s.subs.active1_count + soucasne
      return Math.round(soucasne * 100 / celkem) + "%"

    },

    filteredStats() {
      return this.stats.slice(0, -1);
    }

  }
}
</script>

<template>

  <div class="card card-body">

  <table class="table table-bordered">
    <tr>
      <td></td>
      <th colspan="8">Měsíční předplatné</th>
      <th colspan="5">Půlroční předplatné</th>
      <th colspan="5">Roční předplatné</th>
    </tr>

	  <tr>
      <td></td>
      <td>aktivní</td>
      <td>∑ plateb</td>
      <td>trend</td>
      <td>zrušeno</td>
      <td>v %</td>
      <td>trend</td>
      <td>životnost *</td>
      <td>trend</td>

      <td>aktivní</td>
      <td>∑ plateb</td>
      <td>trend</td>
      <td>nová</td>
      <td>obnovená</td>

      <td>aktivní</td>
      <td>∑ plateb</td>
      <td>trend</td>
      <td>nová</td>
      <td>obnovená</td>

    </tr>
    <tr v-for="s in filteredStats()">
      <td>{{  mesic_z_datumu(s.month) }}</td>
      <td>{{s.subs.active1_count}} </td>
      <td>{{s.subs.sum1_totals}}   </td>
      <td>{{trend1m(s)}}</td>
      <td>{{s.subs.stopped1_count}}</td>
      <td>{{procentoZrusenych(s)}}</td>
      <td>{{trendZrusenych1m(s)}}</td>
      <td>{{s.subs.average_lifetime}}</td>
      <td>{{trendAverageLifetime(s) }}</td>

      <td>{{s.subs.active6_count}}</td>
      <td>{{s.subs.sum6_totals}}</td>
      <td>{{trendSum6Totals(s)}}</td>
      <td>{{s.subs.started6_count}}</td>
      <td>{{s.subs.renew6_count}}</td>

      <td>{{s.subs.active12_count}}</td>
      <td>{{s.subs.sum12_totals}}</td>
      <td>{{trendSum12Totals(s)}}</td>
      <td>{{s.subs.started12_count}}</td>
      <td>{{s.subs.renew12_count}}</td>
    </tr>


  </table>
  <p>* průměrná životnost v měsících</p>
  </div>
  
</template>

<style scoped>

</style>