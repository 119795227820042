<script>
import TopCoursesTable from "./top-courses-table.vue";

import axios from "axios";
export default {
  name: "page-lessons",
  components: {TopCoursesTable},
  data() {
    return {
      top_courses: [],
      top_year_courses: [   [ 473,
            {
              "count": 7199,
              "name": "Angličtina pro začátečníky A1-",
              "cid": 473
            }
          ]
      ]
    };
  },
  created() {
    this.fetchTopCourses()
  },
  methods:{
    fetchTopCourses() {
      axios.get("/students_stats/top_courses")
          .then(response => {
            this.top_courses = response.data.months
            this.top_year_courses = response.data.y
          })
          .catch(error => {
            console.log(error);
          });
    },
  }

}
</script>

<template>
  <div class="col-md-auto box">

    <div class="row" style="padding-top: 50px;">
      <h1 class="col flex-grow-1 heading">Nejstudovanější kurzy</h1>
    </div>

    <hr>

    <h4 class="title pad20">Za poslední 3 měsíce</h4>

    <div class="card card-body">
      <top-courses-table :stats="top_courses"></top-courses-table>
    </div>

    <h4 class="title pad20">V tomto kalendářním roce</h4>
      <div class="card card-body">
        <table class="table table-bordered">
          <tr>
            <td></td>
            <td>Kurz</td>
            <td>počet</td>
          </tr>
          <tr v-for="(c, index) in top_year_courses">
            <td>{{index+1}}</td>
            <td>{{c[1].name}}</td>
            <td>{{c[1].count}}</td>
          </tr>
        </table>
      </div>
  </div>
</template>

<style scoped>

.pad20 {
  padding-top: 20px;
}

</style>